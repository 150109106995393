import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CheckCircle2 } from 'lucide-react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Footer from './Footer';
import locations from '../data/locations';
import { FaMapMarkerAlt, FaPalette, FaDollarSign, FaBuilding } from 'react-icons/fa';

const PricingComponent = ({ isMobile, language }) => {
  return (
    <div className="w-full py-12 mt-20">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-center items-start">
          <div className="w-full md:w-1/3 mb-8 md:mb-0 text-center">
            <h2 className={`text-xl font-bold mb-4 text-white ${!isMobile ? 'mr-10' : ''}`}>
              {language === 'en' ? 'Unbeatable color test:' :
               language === 'es' ? 'Prueba de color imbatible:' :
               'Teste de cor imbatível:'}
            </h2>     
            <ul className={`space-y-3 ${isMobile ? 'mx-auto' : 'inline-block'} text-left`}>
              {[
                language === 'en' ? 'Results in less than 1h' :
                language === 'es' ? 'Resultados en menos de 1 hora' :
                'Resultados em menos de 1 hora',
                language === 'en' ? '10x cheaper than a stylist' :
                language === 'es' ? '10 veces más barato que un estilista' :
                '10 vezes mais barato que um estilista',
                language === 'en' ? 'Actually see yourself in the colors' :
                language === 'es' ? 'Realmente verte en los colores' :
                'Realmente se ver nas cores'
              ].map((item, index) => (
                <li key={index} className={`flex items-center text-gray-200 ${isMobile ? 'justify-center' : ''}`}>
                  <CheckCircle2 className="text-green-500 mr-2 flex-shrink-0" size={20} />
                  <span className={isMobile ? 'text-center' : ''}>
                    {language === 'en' && item === 'Results in less than 1h' ? (
                      <>Results in <strong>less than 1h</strong></>
                    ) : language === 'es' && item === 'Resultados en menos de 1 hora' ? (
                      <>Resultados en <strong>menos de 1 hora</strong></>
                    ) : language === 'pt' && item === 'Resultados em menos de 1 hora' ? (
                      <>Resultados em <strong>menos de 1 hora</strong></>
                    ) : language === 'en' && item === '10x cheaper than a stylist' ? (
                      <><strong>10x cheaper</strong> than a stylist</>
                    ) : language === 'es' && item === '10 veces más barato que un estilista' ? (
                      <><strong>10 veces más barato</strong> que un estilista</>
                    ) : language === 'pt' && item === '10 vezes mais barato que um estilista' ? (
                      <><strong>10 vezes mais barato</strong> que um estilista</>
                    ) : language === 'en' ? (
                      <>Actually <strong>see yourself</strong> in the colors</>
                    ) : language === 'es' ? (
                      <>Realmente <strong>verte</strong> en los colores</>
                    ) : (
                      <>Realmente <strong>se ver</strong> nas cores</>
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          <div className="w-full md:w-1/3 text-center mb-8 md:mb-0">
            <div className="text-center">
        <div className="flex justify-center items-center mb-2">
          {[...Array(5)].map((_, index) => (
            <svg key={index} className="w-6 h-6 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
          ))}
        </div>
        <p className="text-2xl font-semibold text-white mb-4">4.9 out of 5</p>
      </div>
            <div className="w-20 h-1 bg-secondary mx-auto mb-4"></div>
            <p className="text-gray-200 mb-6"><strong>
              {language === 'en' ? 'Loved by our users' :
               language === 'es' ? 'Amado por nuestros usuarios' :
               'Amado pelos nossos usuários'}
            </strong></p>
            <Link to={language === 'en' ? '/login' : `/${language}/login`} className="bg-secondary text-gray-900 font-semibold py-2 px-6 rounded-full hover:bg-opacity-90 transition duration-300 inline-block">
              {language === 'en' ? 'Get Started' :
               language === 'es' ? 'Comenzar' :
               'Começar'}
            </Link>
          </div>

          <div className="w-full md:w-1/3 text-center">
            <h2 className="text-xl font-bold mb-4 text-white">
              {language === 'en' ? 'Every package includes:' :
               language === 'es' ? 'Cada paquete incluye:' :
               'Cada pacote inclui:'}
            </h2>
            <ul className={`space-y-4 ${isMobile ? 'mx-auto' : 'inline-block'} text-left ${isMobile ? '' : 'pl-8'}`}>
              {[
                language === 'en' ? 'Your color analysis playbook' :
                language === 'es' ? 'Tu libro de jugadas de análisis de color' :
                'Seu guia de análise de cores',
                language === 'en' ? 'AI Pictures with the highest quality' :
                language === 'es' ? 'Imágenes de IA con la más alta calidad' :
                'Imagens de IA com a mais alta qualidade',
                language === 'en' ? 'Access to playground to try new styles' :
                language === 'es' ? 'Acceso al área de juegos para probar nuevos estilos' :
                'Acesso ao playground para experimentar novos estilos'
              ].map((item, index) => (
                <li key={index} className={`flex items-center text-gray-200 ${isMobile ? 'justify-center' : ''}`}>
                  <CheckCircle2 className="text-green-500 mr-2 flex-shrink-0" size={20} />
                  <span className={isMobile ? 'text-center' : ''}>
                    {language === 'en' && item === 'Your color analysis playbook' ? (
                      <>Your <strong>color analysis playbook</strong></>
                    ) : language === 'es' && item === 'Tu libro de jugadas de análisis de color' ? (
                      <>Tu <strong>libro de jugadas de análisis de color</strong></>
                    ) : language === 'pt' && item === 'Seu guia de análise de cores' ? (
                      <>Seu <strong>guia de análise de cores</strong></>
                    ) : language === 'en' && item === 'AI Pictures with the highest quality' ? (
                      <><strong>AI Pictures</strong> with the highest quality</>
                    ) : language === 'es' && item === 'Imágenes de IA con la más alta calidad' ? (
                      <><strong>Imágenes de IA</strong> con la más alta calidad</>
                    ) : language === 'pt' && item === 'Imagens de IA com a mais alta qualidade' ? (
                      <><strong>Imagens de IA</strong> com a mais alta qualidade</>
                    ) : language === 'en' ? (
                      <><strong>Photo playground</strong> to try new styles</>
                    ) : language === 'es' ? (
                      <><strong>Área de juegos de fotos</strong> para probar nuevos estilos</>
                    ) : (
                      <><strong>Playground de fotos</strong> para experimentar novos estilos</>
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColorNugget = ({ colors, isMobile }) => {
  return (
    <motion.div
      className={`${isMobile ? 'w-20 mt-0.5' : 'w-5'} ${isMobile ? 'h-36' : 'h-48'} rounded-full overflow-hidden shadow-lg ml-2`}
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      {colors.map((color, index) => (
        <div key={index} className="h-1/3" style={{ backgroundColor: color }} />
      ))}
    </motion.div>
  );
};

const ColorAnalysisLocation = () => {
  const { slug } = useParams();
  const location = useLocation();
  const language = location.pathname.startsWith('/es/') ? 'es' : 
                   location.pathname.startsWith('/pt/') ? 'pt' : 'en';
  
  const locationData = locations.find(loc => loc.slug === slug);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const getStartedRef = useRef(null);

  const translations = {
    en: {
      title: "Color Analysis in",
      description: "Discover your perfect color palette with Palette Hunt's AI-powered analysis in",
      getStarted: "Get started",
      contact: "Contact",
      signIn: "Sign In",
      imagesCreated: "+15,000 images created",
      heroTitle: "Color Analysis",
      heroSubtitle: "Discover your perfect color palette in {location} (or anywhere) with our AI-powered analysis. Our advanced technology creates personalized AI photos of you in your most flattering colors, all from the comfort of your home.",
      uploadPhotos: "Upload a few photos and find your perfect palette now",
      inPersonAnalysis: "In-Person Color Analysis",
      aiPoweredAnalysis: "AI-Powered Color Analysis",
      colorAnalysisIn: "Color Analysis in {location}",
      locationOverview: "Location Overview",
      colorAnalysisServices: "Color Analysis Services",
      colorAnalysisServicesDesc: "{location} offers a diverse range of color analysis services, from traditional in-person consultations to cutting-edge AI-powered solutions like Palette Hunt.",
      pricingInformation: "Pricing Information",
      averagePriceRange: "Average price range for color analysis in {location}:",
      popularColorAnalysisServices: "Popular Color Analysis Services",
      experiencePaletteHunt: "Experience Palette Hunt's AI-powered color analysis, available anywhere in {location}.",
      tryPaletteHuntNow: "Try Palette Hunt Now",
      unbeatable: "Unbeatable color test:",
      resultsIn: "Results in <strong>less than 1h</strong>",
      cheaperThan: "<strong>10x cheaper</strong> than a stylist",
      seeYourself: "Actually <strong>see yourself</strong> in the colors",
      lovedByUsers: "Loved by our users",
      everyPackageIncludes: "Every package includes:",
      colorAnalysisPlaybook: "Your <strong>color analysis playbook</strong>",
      aiPictures: "<strong>AI Pictures</strong> with the highest quality",
      photoPlayground: "<strong>Photo playground</strong> to try new styles",
      yourStyleReimagined: "Your Style, Reimagined",
      stepIntoVirtual: "Step into our virtual try-on studio where imagination knows no bounds. Witness yourself in as many looks and colors as you want.",
      aiPoweredPlayground: "Our AI-powered playground is your canvas for endless style exploration. Create images of yourself in styles you never thought you could wear.",
      paletteHuntTryonStudio: "Palette Hunt Try-on Studio"
    },
    es: {
      title: "Análisis de Color en",
      description: "Descubre tu paleta de colores perfecta con el análisis impulsado por IA de Palette Hunt en",
      getStarted: "Comenzar",
      contact: "Contacto",
      signIn: "Iniciar sesión",
      imagesCreated: "+15,000 imágenes creadas",
      heroTitle: "Análisis de Color",
      heroSubtitle: "Descubre tu paleta de colores perfecta en {location} (o en cualquier lugar) con nuestro análisis impulsado por IA. Nuestra tecnología avanzada crea fotos personalizadas de IA tuyas en tus colores más favorecedores, todo desde la comodidad de tu hogar.",
      uploadPhotos: "Sube algunas fotos y encuentra tu paleta perfecta ahora",
      inPersonAnalysis: "Análisis de Color en Persona",
      aiPoweredAnalysis: "Análisis de Color Impulsado por IA",
      colorAnalysisIn: "Análisis de Color en {location}",
      locationOverview: "Resumen de la Ubicación",
      colorAnalysisServices: "Servicios de Análisis de Color",
      colorAnalysisServicesDesc: "{location} ofrece una amplia gama de servicios de análisis de color, desde consultas tradicionales en persona hasta soluciones de vanguardia impulsadas por IA como Palette Hunt.",
      pricingInformation: "Información de Precios",
      averagePriceRange: "Rango de precios promedio para análisis de color en {location}:",
      popularColorAnalysisServices: "Servicios Populares de Análisis de Color",
      experiencePaletteHunt: "Experimenta el análisis de color impulsado por IA de Palette Hunt, disponible en cualquier lugar de {location}.",
      tryPaletteHuntNow: "Prueba Palette Hunt Ahora",
      unbeatable: "Prueba de color inmejorable:",
      resultsIn: "Resultados en <strong>menos de 1h</strong>",
      cheaperThan: "<strong>10 veces más barato</strong> que un estilista",
      seeYourself: "Realmente <strong>vete</strong> en los colores",
      lovedByUsers: "Amado por nuestros usuarios",
      everyPackageIncludes: "Cada paquete incluye:",
      colorAnalysisPlaybook: "Tu <strong>guía de análisis de color</strong>",
      aiPictures: "<strong>Imágenes de IA</strong> de la más alta calidad",
      photoPlayground: "<strong>Playground de fotos</strong> para probar nuevos estilos",
      yourStyleReimagined: "Tu Estilo, Reinventado",
      stepIntoVirtual: "Entra en nuestro estudio virtual de prueba donde la imaginación no tiene límites. Mírate en tantos looks y colores como quieras.",
      aiPoweredPlayground: "Nuestro playground impulsado por IA es tu lienzo para una exploración de estilo sin fin. Crea imágenes tuyas en estilos que nunca pensaste que podrías usar.",
      paletteHuntTryonStudio: "Estudio de Prueba de Palette Hunt"
    },
    pt: {
      title: "Análise de Cores em",
      description: "Descubra sua paleta de cores perfeita com a análise impulsionada por IA da Palette Hunt em",
      getStarted: "Começar",
      contact: "Contato",
      signIn: "Entrar",
      imagesCreated: "+15.000 imagens criadas",
      heroTitle: "Análise de Cores",
      heroSubtitle: "Descubra sua paleta de cores perfeita em {location} (ou em qualquer lugar) com nossa análise impulsionada por IA. Nossa tecnologia avançada cria fotos personalizadas de IA suas em suas cores mais favoráveis, tudo no conforto da sua casa.",
      uploadPhotos: "Envie algumas fotos e encontre sua paleta perfeita agora",
      inPersonAnalysis: "Análise de Cores Presencial",
      aiPoweredAnalysis: "Análise de Cores Impulsionada por IA",
      colorAnalysisIn: "Análise de Cores em {location}",
      locationOverview: "Visão Geral da Localização",
      colorAnalysisServices: "Serviços de Análise de Cores",
      colorAnalysisServicesDesc: "{location} oferece uma ampla gama de serviços de análise de cores, desde consultas tradicionais presenciais até soluções de ponta impulsionadas por IA como a Palette Hunt.",
      pricingInformation: "Informações de Preços",
      averagePriceRange: "Faixa de preço média para análise de cores em {location}:",
      popularColorAnalysisServices: "Serviços Populares de Análise de Cores",
      experiencePaletteHunt: "Experimente a análise de cores impulsionada por IA da Palette Hunt, disponível em qualquer lugar em {location}.",
      tryPaletteHuntNow: "Experimente a Palette Hunt Agora",
      unbeatable: "Teste de cores imbatível:",
      resultsIn: "Resultados em <strong>menos de 1h</strong>",
      cheaperThan: "<strong>10 vezes mais barato</strong> que um estilista",
      seeYourself: "Realmente <strong>veja-se</strong> nas cores",
      lovedByUsers: "Amado pelos nossos usuários",
      everyPackageIncludes: "Cada pacote inclui:",
      colorAnalysisPlaybook: "Seu <strong>guia de análise de cores</strong>",
      aiPictures: "<strong>Imagens de IA</strong> com a mais alta qualidade",
      photoPlayground: "<strong>Playground de fotos</strong> para experimentar novos estilos",
      yourStyleReimagined: "Seu Estilo, Reinventado",
      stepIntoVirtual: "Entre em nosso estúdio virtual de experimentação onde a imaginação não tem limites. Veja-se em quantos looks e cores quiser.",
      aiPoweredPlayground: "Nosso playground impulsionado por IA é sua tela para uma exploração de estilo sem fim. Crie imagens suas em estilos que você nunca pensou que poderia usar.",
      paletteHuntTryonStudio: "Estúdio de Experimentação da Palette Hunt"
    }
  };

  const t = translations[language] || translations.en;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateArrows();
      window.addEventListener('resize', updateArrows);
    }, 100);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', updateArrows);
    };
  }, [isMobile]);

  const updateArrows = () => {
    if (containerRef.current && svgRef.current) {
      const container = containerRef.current;
      const svg = svgRef.current;

      svg.innerHTML = '';

      const drawArrow = (
        startEl,
        endEl,
        startXPercent,
        startYPercent,
        endXPercent,
        endYPercent,
        curveFactor = 0.5,
        color = '#FFA500',
        addText = false,
        textContent = '',
        textOffsetX = 0,
        textOffsetY = 0
      ) => {
        if (!startEl || !endEl) return;

        const startRect = startEl.getBoundingClientRect();
        const endRect = endEl.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        const startX = startRect.left + startRect.width * startXPercent - containerRect.left;
        const startY = startRect.top + startRect.height * startYPercent - containerRect.top;
        const endX = endRect.left + endRect.width * endXPercent - containerRect.left;
        const endY = endRect.top + endRect.height * endYPercent - containerRect.top;

        const midX = (startX + endX) / 2;
        const midY = (startY + endY) / 2;

        const dx = endX - startX;
        const dy = endY - startY;
        const normalX = -dy;
        const normalY = dx;
        const distance = Math.sqrt(dx * dx + dy * dy);

        const controlX = midX + (normalX / distance) * curveFactor * 100;
        const controlY = midY + (normalY / distance) * curveFactor * 100;

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttribute('d', `M${startX},${startY} Q${controlX},${controlY} ${endX},${endY}`);
        path.setAttribute('fill', 'none');
        path.setAttribute('stroke', color);
        path.setAttribute('stroke-width', '2');
        svg.appendChild(path);

        const arrowSize = 10;
        const arrowWidth = 6;
        const arrowAngle = Math.atan2(endY - controlY, endX - controlX);
        const arrowhead = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        arrowhead.setAttribute(
          'd',
          `M${endX},${endY} 
              L${endX - arrowSize * Math.cos(arrowAngle - Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle - Math.PI / 6)}
              L${endX - (arrowSize - arrowWidth / 2) * Math.cos(arrowAngle)},${endY - (arrowSize - arrowWidth / 2) * Math.sin(arrowAngle)}
              L${endX - arrowSize * Math.cos(arrowAngle + Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle + Math.PI / 6)}
              Z`
        );
        arrowhead.setAttribute('fill', color);
        svg.appendChild(arrowhead);

        if (addText) {
          const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          text.setAttribute('x', endX + textOffsetX);
          text.setAttribute('y', endY + textOffsetY);
          text.setAttribute('fill', color);
          text.setAttribute('font-family', '"Nerko One", cursive');
          text.setAttribute('font-size', '17px');
          text.setAttribute('font-weight', 'normal');
          text.textContent = textContent;
          svg.appendChild(text);
        }
      };

      const lauritaSmall = container.querySelector('[alt="Palette Hunt Selfie Small 3 - ' + locationData.slug + '"]');
      const lauritaLarge = container.querySelector('[alt="Palette Hunt Transformation Large 2 - ' + locationData.slug + '"]');
      const olympiaSmall = container.querySelector('[alt="Palette Hunt Selfie Small Olympia 3 - ' + locationData.slug + '"]');
      const olympiaLarge = container.querySelector('[alt="Palette Hunt Transformation Large 4 - ' + locationData.slug + '"]');
      const olympiaSmall1 = container.querySelector('[alt="Palette Hunt Selfie Small Olympia 1 - ' + locationData.slug + '"]');
      const colorPalette = container.querySelector('[alt="Palette Hunt Transformation Large 1 - ' + locationData.slug + '"]');

      drawArrow(
        lauritaSmall,
        lauritaLarge,
        isMobile ? 1.1 : 1.1,
        isMobile ? 0.7 : 0.7,
        isMobile ? 0.85 : 0.7,
        isMobile ? -0.05 : -0.05,
        isMobile ? -0.25 : -0.25,
        '#FFFFFF',
        false
      );

      drawArrow(
        olympiaSmall,
        olympiaLarge,
        1.15,
        0.35,
        isMobile ? 0.8 : 0.7,
        isMobile ? 1.05 : 1.05,
        isMobile ? 0.35 : 0.25,
        '#FFFFFF',
        false
      );

      const drawUploadArrow = () => {
        if (!olympiaSmall1) return;
      
        const rect = olympiaSmall1.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
      
        const startX = rect.left - containerRect.left - (isMobile ? 26 : 60);
        const startY = rect.top - containerRect.top + rect.height / 2;
        const endX = rect.left - containerRect.left - (isMobile ? 2 : 5);
        const endY = rect.top - containerRect.top + rect.height / 2;
      
        const controlX = startX - (isMobile ? 10 : 20);
        const controlY = startY + (isMobile ? 20 : 40);
      
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttribute('d', `M${isMobile ? startX + 2 : startX},${isMobile ? startY - 18 : startY} Q${controlX},${controlY} ${endX},${endY}`);
        path.setAttribute('fill', 'none');
        path.setAttribute('stroke', '#FFA500');
        path.setAttribute('stroke-width', '2');
        svg.appendChild(path);
      
        const arrowSize = isMobile ? 8 : 10;
        const arrowAngle = Math.atan2(endY - controlY, endX - controlX);
        const arrowhead = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        arrowhead.setAttribute(
          'd',
          `M${endX},${endY} 
              L${endX - arrowSize * Math.cos(arrowAngle - Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle - Math.PI / 6)}
              L${endX - arrowSize * Math.cos(arrowAngle + Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle + Math.PI / 6)}
              Z`
        );
        arrowhead.setAttribute('fill', '#FFA500');
        svg.appendChild(arrowhead);
      
        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', startX - (isMobile ? 55 : 85));
        text.setAttribute('y', startY - (isMobile ? 44 : 48));
        text.setAttribute('fill', '#FFA500');
        text.setAttribute('font-family', '"Nerko One", cursive');
        text.setAttribute('font-size', isMobile ? '14px' : '17px');
        text.setAttribute('font-weight', 'normal');
        text.setAttribute('transform', `rotate(-20, ${startX + (isMobile ? 45 : 90)}, ${startY - (isMobile ? 55 : 90)})`);
        text.textContent = 'Upload some pics';
        svg.appendChild(text);
      };

      const drawAIGeneratedArrow = () => {
        if (!colorPalette) return;

        const rect = colorPalette.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        let startX, startY, endX, endY, controlX, controlY;

        if (isMobile) {
          startX = rect.left - containerRect.left + 25;
          startY = rect.top - containerRect.top - 35;
          endX = rect.left - containerRect.left + 30;
          endY = rect.top - containerRect.top - 5;
          controlX = startX - 20;
          controlY = startY + 15;
        } else {
          startX = rect.left - containerRect.left - 40;
          startY = rect.top - containerRect.top + rect.height * 0.8;
          endX = rect.left - containerRect.left - 5;
          endY = rect.top - containerRect.top + rect.height * 0.8;
          controlX = startX - 10;
          controlY = startY + 20;
        }

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttribute('d', `M${startX},${startY} Q${controlX},${controlY} ${endX},${endY}`);
        path.setAttribute('fill', 'none');
        path.setAttribute('stroke', '#FFA500');
        path.setAttribute('stroke-width', '2');
        svg.appendChild(path);

        const arrowSize = isMobile ? 5 : 10;
        const arrowAngle = Math.atan2(endY - controlY, endX - controlX);
        const arrowhead = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        arrowhead.setAttribute(
          'd',
          `M${endX},${endY} 
            L${endX - arrowSize * Math.cos(arrowAngle - Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle - Math.PI / 6)}
            L${endX - arrowSize * Math.cos(arrowAngle + Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle + Math.PI / 6)}
            Z`
        );
        arrowhead.setAttribute('fill', '#FFA500');
        svg.appendChild(arrowhead);

        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        if (isMobile) {
          text.setAttribute('x', startX - 18);
          text.setAttribute('y', startY - 5);
          text.setAttribute('transform', `rotate(-10, ${startX - 30}, ${startY + 20})`);
        } else {
          text.setAttribute('x', startX - 55);
          text.setAttribute('y', startY + 5);
          text.setAttribute('transform', `rotate(-20, ${startX - 50}, ${startY - 5})`);
        }
        text.setAttribute('fill', '#FFA500');
        text.setAttribute('font-family', '"Nerko One", cursive');
        text.setAttribute('font-size', isMobile ? '14px' : '17px');
        text.setAttribute('font-weight', 'normal');
        text.textContent = isMobile ? 'AI photos' : 'AI generated';
        svg.appendChild(text);
      };

      drawUploadArrow();
      drawAIGeneratedArrow();
    }
  };

  if (!locationData) {
    return <div>Location not found</div>;
  }

  const ImageWithFallback = ({ src, alt, className, objectPosition }) => {
    return (
      <div className={`relative ${className} ${!isMobile ? 'transition-transform duration-300 ease-in-out transform hover:scale-110' : ''}`}>
        <LazyLoadImage
          src={src}
          alt={`${alt} - ${locationData.slug}`}
          effect="blur"
          className="w-full h-full object-cover rounded-lg"
          style={{ objectPosition: objectPosition || 'center' }}
        />
      </div>
    );
  };

  const scrollToGetStarted = () => {
    getStartedRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  // Function to generate the correct canonical URL
  const getCanonicalUrl = (pathname) => {
    const baseUrl = 'https://www.palettehunt.com';
    const language = pathname.startsWith('/es/') ? 'es' : 
                     pathname.startsWith('/pt/') ? 'pt' : 'en';

    if (language === 'en') {
      return `${baseUrl}/color-analysis/${slug}`;
    } else {
      return `${baseUrl}/${language}/color-analysis/${slug}`;
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t.title} ${locationData.name} | Palette Hunt`}</title>
        <meta name="description" content={`${t.description} ${locationData.name}. Transform your style, try on virtual looks, and get personalized recommendations. Unlock your true fashion potential today!`} />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>
      <div className="bg-gray-900 text-white flex flex-col min-h-screen">
        <header className="w-full p-4">
          <nav className="max-w-7xl mx-auto flex justify-between items-center">
            <div className="flex items-center">
              <Link to={language === 'en' ? '/' : `/${language}`} className="flex items-center">
                <img
                  src="/logo.png"
                  alt={`Palette Hunt Logo - ${locationData.slug}`}
                  className="h-12 w-auto sm:h-16"
                  effect="blur"
                />
                <span className="ml-2 sm:ml-3 text-xl sm:text-2xl text-white font-bold text-primary">Palette Hunt</span>
              </Link>
            </div>
            <div className="flex-shrink-0 pr-4 sm:pr-32">
              {!isMobile && (
                <a
                  href="mailto:hi@palettehunt.com"
                  className="text-white hover:text-primary transition duration-300 mr-0 sm:mr-16 md:mr-8"
                >
                  {t.contact}
                </a>
              )}
              <Link
                to={language === 'en' ? '/login' : `/${language}/login`}
                className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 text-sm sm:text-base"
              >
                {t.signIn}
              </Link>
            </div>
          </nav>
        </header>
        <main className="flex-grow">
          <section className="hero max-w-7xl mx-auto px-4 pt-12 sm:pt-12 pb-12">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0 text-center md:text-left">
                <div
                  className="inline-block mb-3 bg-gradient-to-r from-primary to-secondary rounded-full px-4 py-1 sm:px-5 sm:py-2 text-xs sm:text-sm font-bold text-white shadow-lg transform hover:scale-105 transition-transform duration-300"
                  style={{ zIndex: 20 }}
                >
                  <span className="mr-1 sm:mr-2 text-base sm:text-lg">🎨</span>
                  {t.imagesCreated}
                </div>
                <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-4 sm:mb-8 text-center md:text-left">
                  {/* <span className="block mb-2">{t.heroTitle}</span> */}
                  {t.title} {locationData.name}
                </h1>
                <h2 className="text-lg sm:text-xl text-gray-300 mb-6 sm:mb-10 max-w-lg mx-auto md:mx-0">
                  {t.heroSubtitle.replace('{location}', locationData.name)}
                </h2>
                <Link
                  to={language === 'en' ? '/login' : `/${language}/login`}
                  ref={getStartedRef}
                  className="bg-primary text-white font-semibold py-2 px-4 sm:py-3 sm:px-6 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 text-lg sm:text-xl inline-block mx-auto md:mx-0 mb-12 md:mb-0"
                >
                  {t.getStarted}
                </Link>
              </div>
              <div className="md:w-1/2 flex items-center justify-center px-4 md:px-0">
                <div ref={containerRef} className="relative w-full h-auto md:h-[600px] mb-16 md:mb-0">
                  <svg ref={svgRef} className="absolute top-0 left-0 w-full h-full text-secondary pointer-events-none z-10" />
                  {/* Images */}
                  {/* First row of small images */}
                  <div className="flex justify-center mb-2 md:mb-3">
                    <div className="flex space-x-2">
                      <LazyLoadImage src="/laurita.jpg" alt={`Palette Hunt Selfie Small 1 - ${locationData.slug}`} effect="blur" className="w-16 h-16 object-cover rounded-lg" style={{ objectPosition: 'center 30%' }} />
                      <LazyLoadImage src="/laurita4.jpg" alt={`Palette Hunt Selfie Small 2 - ${locationData.slug}`} effect="blur" className="w-16 h-16 object-cover rounded-lg" />
                      <LazyLoadImage src="/laurita-6.jpg" alt={`Palette Hunt Selfie Small 3 - ${locationData.slug}`} effect="blur" className="w-16 h-16 object-cover rounded-lg" style={{ objectPosition: 'center 30%' }} />
                    </div>
                  </div>
                  {/* Large images with arrow */}
                  <div className="flex justify-center mt-6 mb-2 md:mb-3">
                    <div className="flex space-x-2 items-start">
                      <LazyLoadImage src="/colorPalette.jpeg" alt={`Palette Hunt Transformation Large 1 - ${locationData.slug}`} effect="blur" className="w-40 h-40 md:w-48 md:h-48 object-cover rounded-lg" />
                      <div className="relative">
                        <LazyLoadImage src="/sunny-yellow.webp" alt={`Palette Hunt Transformation Large 2 - ${locationData.slug}`} effect="blur" className="w-40 h-40 md:w-48 md:h-48 object-cover rounded-lg" />
                      </div>
                      <ColorNugget colors={['#FFD700', '#E2725B', '#8B4513']} isMobile={isMobile} />
                    </div>
                  </div>
                  {/* Second set of large images */}
                  <div className={`flex justify-center mb-2 md:mb-3 ${isMobile ? 'mt-4' : ''}`}>
                    <div className="flex space-x-2 items-start">
                      <LazyLoadImage src="/olympia-ai-2.jpeg" alt={`Palette Hunt Transformation Large 3 - ${locationData.slug}`} effect="blur" className="w-40 h-40 md:w-48 md:h-48 object-cover rounded-lg" />
                      <LazyLoadImage src="/olympia-ai-1.jpeg" alt={`Palette Hunt Transformation Large 4 - ${locationData.slug}`} effect="blur" className="w-40 h-40 md:w-48 md:h-48 object-cover rounded-lg" />
                    </div>
                    <ColorNugget colors={['#80CCFF', '#FFB3B3', '#B266FF']} isMobile={isMobile} />
                  </div>
                  {/* Second row of small images */}
                  <div className={`flex justify-center ${isMobile ? 'mt-6 md:mt-6' : 'mt-2 md:mt-6'}`}>
                    <div className="flex space-x-2 items-end">
                      <div className="relative">
                        <LazyLoadImage src="/olympia-small-1.jpg" alt={`Palette Hunt Selfie Small Olympia 1 - ${locationData.slug}`} effect="blur" className="w-16 h-16 object-cover rounded-lg" />
                      </div>
                      <LazyLoadImage src="/olympia-small-2.jpg" alt={`Palette Hunt Selfie Small Olympia 2 - ${locationData.slug}`} effect="blur" className="w-16 h-16 object-cover rounded-lg" style={{ objectPosition: 'center 50%' }} />
                      <div className="relative">
                        <LazyLoadImage src="/olympia-small-3.jpg" alt={`Palette Hunt Selfie Small Olympia 3 - ${locationData.slug}`} effect="blur" className="w-16 h-16 object-cover rounded-lg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* New Location-Specific Information Section */}
          <section className="py-16 bg-gradient-to-b from-gray-800 to-gray-900">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">
                {t.colorAnalysisIn.replace('{location}', locationData.name)}
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-8">
                  <div className="bg-gray-800 rounded-lg p-6 shadow-lg transform transition-all duration-300 hover:scale-105">
                    <FaMapMarkerAlt className="text-4xl text-primary mb-4" />
                    <h3 className="text-2xl font-semibold mb-4">{t.locationOverview}</h3>
                    <p className="text-gray-300 leading-relaxed">{locationData.description}</p>
                  </div>
                  <div className="bg-gray-800 rounded-lg p-6 shadow-lg transform transition-all duration-300 hover:scale-105">
                    <FaPalette className="text-4xl text-secondary mb-4" />
                    <h3 className="text-2xl font-semibold mb-4">{t.colorAnalysisServices}</h3>
                    <p className="text-gray-300 leading-relaxed">
                      {t.colorAnalysisServicesDesc.replace('{location}', locationData.name)}
                    </p>
                  </div>
                  <div className="bg-gray-800 rounded-lg p-6 shadow-lg transform transition-all duration-300 hover:scale-105">
                    <FaDollarSign className="text-4xl text-green-500 mb-4" />
                    <h3 className="text-2xl font-semibold mb-4">{t.pricingInformation}</h3>
                    <p className="text-gray-300 mb-4">{t.averagePriceRange.replace('{location}', locationData.name)}</p>
                    <p className="text-3xl font-bold text-green-400">{locationData.pricingRange}</p>
                  </div>
                </div>
                <div className="space-y-8">
                  
                  <div className="bg-gray-800 rounded-lg p-6 shadow-lg transform transition-all duration-300 hover:scale-105">
                    <FaBuilding className="text-4xl text-purple-500 mb-4" />
                    <h3 className="text-2xl font-semibold mb-4">{t.popularColorAnalysisServices}</h3>
                    <ul className="space-y-2">
                      {locationData.businesses.map((business, index) => (
                        <li key={index} className="flex flex-col text-gray-300">
                          <span className="flex items-center">
                            <span className="w-2 h-2 bg-purple-500 rounded-full mr-2"></span>
                            <span className="font-semibold">
                              {typeof business === 'string' ? business : business.name}
                            </span>
                          </span>
                          {typeof business !== 'string' && business.description && (
                            <p className="ml-4">{business.description}</p>
                          )}
                          {typeof business !== 'string' && business.website && (
                            <a href={business.website} className="ml-4 text-blue-400 hover:underline" target="_blank" rel="noopener noreferrer">
                              {business.website}
                            </a>
                          )}
                          {typeof business !== 'string' && business.instagram && (
                            <a href={`https://instagram.com/${business.instagram}`} className="ml-4 text-pink-400 hover:underline" target="_blank" rel="noopener noreferrer">
                              @{business.instagram}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-12 text-center">
                <p className="text-xl text-gray-300 mb-6">
                  {t.experiencePaletteHunt.replace('{location}', locationData.name)}
                </p>
                <Link
                  to={language === 'en' ? '/login' : `/${language}/login`}
                  className="bg-gradient-to-r from-primary to-secondary text-white font-semibold py-3 px-8 rounded-full hover:opacity-90 transition-all duration-300 ease-in-out transform hover:scale-105 shadow-lg inline-block text-lg"
                >
                  {t.tryPaletteHuntNow}
                </Link>
              </div>
            </div>
          </section>

          {/* PricingComponent */}
          <PricingComponent isMobile={isMobile} language={language} />

          {/* Testimonial Section */}
          <section className="testimonials bg-gray-100 py-24 w-full">
            <div className="max-w-6xl mx-auto px-4">
              <h2 className="text-3xl font-bold text-gray-800 mb-12">
                {language === 'en' ? 'One picture is worth a thousand words' :
                 language === 'es' ? 'Una imagen vale más que mil palabras' :
                 'Uma imagem vale mais que mil palavras'}
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="relative">
                  <p className="text-xl text-gray-700 italic mb-6 relative z-10">
                    {language === 'en' ? 'I never thought yellow colors would look good on me, but seeing myself in the colors has changed my mind.' :
                     language === 'es' ? 'Nunca pensé que los colores amarillos me quedarían bien, pero verme en los colores ha cambiado mi opinión.' :
                     'Nunca pensei que as cores amarelas ficariam bem em mim, mas me ver nas cores mudou minha opinião.'}
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <h3 className="text-lg font-semibold text-gray-800 mr-2">Laura</h3>
                      <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                        {language === 'en' ? '✓ Verified User' :
                         language === 'es' ? '✓ Usuario Verificado' :
                         '✓ Usuário Verificado'}
                      </span>
                    </div>
                    <div className="flex">
                      {[...Array(5)].map((_, i) => (
                        <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <p className="text-xl text-gray-700 italic mb-6 relative z-10">
                    {language === 'en' ? 'I used to always stick to the same color shades I felt comfortable in, but seeing the color analysis results on myself has helped me to try new things.' :
                     language === 'es' ? 'Solía limitarme a los mismos tonos de color en los que me sentía cómoda, pero ver los resultados del análisis de color en mí misma me ha ayudado a probar cosas nuevas.' :
                     'Eu costumava me ater sempre aos mesmos tons de cor com os quais me sentia confortável, mas ver os resultados da análise de cores em mim mesma me ajudou a experimentar coisas novas.'}
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <h3 className="text-lg font-semibold text-gray-800 mr-2">Olympia</h3>
                      <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                        {language === 'en' ? '✓ Verified User' :
                         language === 'es' ? '✓ Usuario Verificado' :
                         '✓ Usuário Verificado'}
                      </span>
                    </div>
                    <div className="flex">
                      {[...Array(5)].map((_, i) => (
                        <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Redesigned How it works section */}
          <section className="how-it-works bg-gray-900 py-16 sm:py-24">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-4xl font-bold text-center text-white mb-12 sm:mb-16">
                {t.uploadPhotos}
              </h2>
              {isMobile ? (
                <div className="flex flex-col items-center">
                  <LazyLoadImage
                    src="/grid_with_arrow_phone.png"
                    alt={`Palette Hunt - Mobile app for ${locationData.name}`}
                    className="w-full max-w-4xl mb-4"
                    effect="blur"
                  />
                  <LazyLoadImage
                    src="/result.jpeg"
                    alt={`Palette Hunt Color Analysis for ${locationData.name}`}
                    className="w-full max-w-4xl mb-4 rounded-lg shadow-lg"
                    effect="blur"
                  />
                  <LazyLoadImage
                    src="/result2.jpeg"
                    alt={`Palette Hunt Color Palette for ${locationData.name}`}
                    className="w-full max-w-4xl rounded-lg shadow-lg"
                    effect="blur"
                  />
                </div>
              ) : (
                <div className="flex items-start">
                  <div className="w-5/12 ml-6 pl-12 pr-4 pt-20">
                    <LazyLoadImage
                      src="/grid_with_arrow.png"
                      alt={`Palette Hunt colors for ${locationData.name}`}
                      className="w-full"
                      effect="blur"
                    />
                  </div>
                  <div className="w-1/2 space-y-8 ml-2">
                    <LazyLoadImage
                      src="/result.jpeg"
                      alt={`Palette Hunt colors fit for ${locationData.name}`}
                      className="w-full rounded-lg shadow-lg"
                      effect="blur"
                    />
                    <LazyLoadImage
                      src="/result2.jpeg"
                      alt={`Palette Hunt colors palette for ${locationData.name}`}
                      className="w-full rounded-lg shadow-lg"
                      effect="blur"
                    />
                  </div>
                </div>
              )}
            </div>
          </section>

          {/* Playground Demo Section */}
          <section className="playground-demo py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className={`flex flex-col ${isMobile ? '' : 'lg:flex-row'} items-center justify-between ${isMobile ? '' : 'lg:space-x-16'}`}>
                <div className={`${isMobile ? 'w-full text-center' : 'lg:w-1/2 mb-8 lg:mb-0'}`}>
                  <h2 className="text-4xl font-bold mb-6 text-white">{t.yourStyleReimagined}</h2>
                  <p className="text-xl text-gray-300 mb-4">
                    {t.stepIntoVirtual}
                  </p>
                  <p className="text-xl text-gray-300 mb-6">
                    {t.aiPoweredPlayground}
                  </p>
                </div>
                <div className={`${isMobile ? 'w-full' : 'lg:w-1/2'}`}>
                  <div className={`rounded-lg overflow-hidden shadow-xl ${isMobile ? 'mt-6' : 'skew-right transition-all duration-300 hover:skew-none'}`}>
                    <ReactPlayer
                      url="/playground_movie.mp4"
                      width="100%"
                      height="auto"
                      controls
                      playing={false}
                      muted={false}
                      light={
                        <img 
                          src="/playground-thumbnail.png" 
                          alt={`${t.paletteHuntTryonStudio} - ${locationData.slug}`}
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        
        {/* Color Analysis Demo Section */}
        <section className="color-analysis-demo py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className={`flex flex-col ${isMobile  ? '' : 'lg:flex-row'} items-center justify-between ${isMobile ? '' : 'lg:space-x-16'}`}>
                <div className={`${isMobile  ? 'w-full text-center' : 'lg:w-1/2 mb-8 lg:mb-0 order-2'}`}>
                  <h2 className="text-4xl font-bold mb-6 text-white">
                    {language === 'en' ? 'Crack Your Color Code' :
                     language === 'es' ? 'Descifra Tu Código de Color' :
                     'Decifre Seu Código de Cor'}
                  </h2>
                  <p className="text-xl text-gray-300 mb-4">
                    {language === 'en' ? 'A color analysis shouldn\'t only be for the rich. Find out what colors look good on you and how to use them to your advantage.' :
                     language === 'es' ? 'Un análisis de color no debería ser solo para los ricos. Descubre qué colores te quedan bien y cómo usarlos a tu favor.' :
                     'Uma análise de cores não deve ser apenas para os ricos. Descubra quais cores ficam bem em você e como usá-las a seu favor.'}
                  </p>
                  <p className="text-xl text-gray-300 mb-6">
                    {language === 'en' ? 'We don\'t just analyze your most flattering colors, we also show you how they look on you.' :
                     language === 'es' ? 'No solo analizamos tus colores más favorecedores, también te mostramos cómo se ven en ti.' :
                     'Não apenas analisamos suas cores mais favoráveis, também mostramos como elas ficam em você.'}
                  </p>
                </div>
                <div className={`${isMobile  ? 'w-full' : 'lg:w-1/2 order-1'}`}>
                  <div className={`rounded-lg overflow-hidden shadow-xl ${isMobile  ? 'mt-6' : 'skew-left transition-all duration-300 hover:skew-none'}`} style={{ marginRight: isMobile ? '0' : '15%' }}>
                    <ReactPlayer
                      url="/playbook_demo.mov"
                      width="100%"
                      height="auto"
                      controls
                      playing={false}
                      muted={false}
                      light={
                        <img 
                          src="/playbook-thumbnail.jpeg" 
                          alt="Palette Hunt Analysis Demo" 
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                          effect="blur"
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Playground Testimonial Section */}
          <section className="comparison py-24 bg-gray-100 w-full">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-3xl font-bold text-gray-800 mb-12">
                {language === 'en' ? 'Try new styles you never thought you could wear' :
                 language === 'es' ? 'Prueba nuevos estilos que nunca pensaste que podrías usar' :
                 'Experimente novos estilos que você nunca pensou que poderia usar'}
              </h2>
              <div className="max-w-3xl mx-auto">
                <div className="relative">
                  <p className="text-2xl text-gray-700 italic mb-8 relative z-10">
                    {language === 'en' ? 'The try-on studio feature is amazing. I am literally able to try all colors and styles I want before I go to the store. It\'s like having a personal stylist at my fingertips!' :
                     language === 'es' ? 'La función del estudio de prueba es increíble. Literalmente puedo probar todos los colores y estilos que quiero antes de ir a la tienda. ¡Es como tener un estilista personal al alcance de la mano!' :
                     'O recurso do estúdio de prova é incrível. Eu literalmente posso experimentar todas as cores e estilos que quero antes de ir à loja. É como ter um estilista pessoal ao alcance dos dedos!'}
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <h3 className="text-xl font-semibold text-gray-800 mr-2">
                        {language === 'en' ? 'Louise' :
                         language === 'es' ? 'Luisa' :
                         'Luísa'}
                      </h3>
                      <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                        {language === 'en' ? '✓ Verified User' :
                         language === 'es' ? '✓ Usuario Verificado' :
                         '✓ Usuário Verificado'}
                      </span>
                    </div>
                    <div className="flex">
                      {[...Array(5)].map((_, i) => (
                        <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Redesigned Comparison Section */}
          <section className="price-comparison py-20">
            <div className="max-w-7xl mx-auto px-4">
              <h2 className="text-5xl font-bold text-center text-white mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
                {language === 'en' ? 'Experience the Future of Color Analysis' :
                 language === 'es' ? 'Experimenta el Futuro del Análisis de Color' :
                 'Experimente o Futuro da Análise de Cores'}
              </h2>
              <p className="text-xl text-center text-gray-300 mb-16 max-w-3xl mx-auto">
                {language === 'en' ? 'Discover colors that perfectly complement your unique features, including shades you\'ve never tried before. Unlock your full style potential.' :
                 language === 'es' ? 'Descubre colores que complementan perfectamente tus características únicas, incluyendo tonos que nunca antes habías probado. Desbloquea todo tu potencial de estilo.' :
                 'Descubra cores que complementam perfeitamente suas características únicas, incluindo tons que você nunca experimentou antes. Desbloqueie todo o seu potencial de estilo.'}
              </p>
              <div className="flex flex-col lg:flex-row justify-between items-stretch space-y-12 lg:space-y-0 lg:space-x-12">
                {/* Traditional Method */}
                <div className="w-full lg:w-1/2 bg-gradient-to-br from-gray-800 to-gray-900 rounded-3xl p-8 shadow-2xl transform transition-all duration-300 hover:scale-105 relative overflow-hidden">
                  <div className="absolute top-0 left-0 w-full h-full bg-opacity-10 bg-white rounded-3xl"></div>
                  <h3 className="text-3xl font-semibold mb-6 text-white relative z-10">
                    {language === 'en' ? 'In-Person Color Analysis' :
                     language === 'es' ? 'Análisis de Color en Persona' :
                     'Análise de Cores Presencial'}
                  </h3>
                  <div className={`mb-8 h-72 rounded-xl overflow-hidden bg-gray-700 relative z-10 ${!(isMobile) ? 'cursor-pointer' : ''}`}>
                    <img 
                      src="/traditional-analysis-1.webp" 
                      alt="Palette Hunt Traditional Color Analysis" 
                      className={`w-full h-full object-cover object-top transition-transform duration-300 ${!(isMobile) ? 'transform hover:scale-110' : ''}`}
                      style={!(isMobile) ? { objectPosition: 'center -90px' } : {}}
                      effect="blur"
                    />
                  </div>
                  <ul className="space-y-4 relative z-10">
                    {[
                      language === 'en' ? "Time-consuming <strong>in-person appointments</strong>" :
                      language === 'es' ? "Citas <strong>en persona</strong> que consumen mucho tiempo" :
                      "<strong>Consultas presenciais</strong> demoradas",
                      language === 'en' ? "<strong>Expensive</strong> consultations" :
                      language === 'es' ? "Consultas <strong>costosas</strong>" :
                      "Consultas <strong>caras</strong>",
                      language === 'en' ? "<strong>No try-on</strong> of recommended colors" :
                      language === 'es' ? "<strong>Sin prueba</strong> de colores recomendados" :
                      "<strong>Sem experimentação</strong> das cores recomendadas",
                      language === 'en' ? "Limited to <strong>local experts</strong>" :
                      language === 'es' ? "Limitado a <strong>expertos locales</strong>" :
                      "Limitado a <strong>especialistas locais</strong>"
                    ].map((item, index) => (
                      <li key={index} className="flex items-center text-white">
                        <svg className="w-5 h-5 mr-3 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M4.146 4.146a1.5 1.5 0 012.121 0L10 7.879l3.732-3.733a1.5 1.5 0 112.121 2.121L12.121 10l3.732 3.732a1.5 1.5 0 01-2.121 2.121L10 12.121l-3.732 3.732a1.5 1.5 0 01-2.121-2.121L7.879 10 4.146 6.268a1.5 1.5 0 010-2.122z" clipRule="evenodd" />
                        </svg>
                        <span dangerouslySetInnerHTML={{ __html: item }}></span>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* AI-Powered Method */}
                <div className="w-full lg:w-1/2 rounded-3xl p-8 shadow-2xl transform transition-all duration-300 hover:scale-105 relative overflow-hidden">
                  <div className="absolute top-0 left-0 w-full h-full bg-opacity-10 bg-white rounded-3xl"></div>
                  <h3 className="text-3xl font-semibold mb-6 relative z-10 bg-clip-text text-transparent bg-gradient-to-r from-[#7C83FF] to-secondary">
                    {language === 'en' ? 'AI-Powered Color Analysis' :
                     language === 'es' ? 'Análisis de Color Impulsado por IA' :
                     'Análise de Cores Impulsionada por IA'}
                  </h3>
                  <div className="mb-8 h-72 rounded-xl overflow-hidden flex justify-center items-center space-x-4 relative z-10">
                    <div className={`w-1/2 h-full rounded-xl shadow-lg overflow-hidden ${!(isMobile) ? 'cursor-pointer' : ''}`}>
                      <img 
                        src="/ai-analysis-1.png" 
                        alt="Palette Hunt AI-Powered Color Analysis 1" 
                        className={`w-full h-full object-cover transition-transform duration-300 ${!(isMobile) ? 'transform hover:scale-110' : ''}`}
                        effect="blur"
                      />
                    </div>
                    <div className={`w-1/2 h-full rounded-xl shadow-lg overflow-hidden ${!(isMobile) ? 'cursor-pointer' : ''}`}>
                      <img 
                        src="/ai-analysis-3.webp" 
                        alt="AI-Powered Color Analysis 2" 
                        className={`w-full h-full object-cover transition-transform duration-300 ${!(isMobile) ? 'transform hover:scale-110' : ''}`}
                        style={{ objectPosition: 'center 0px' }}
                        effect="blur"
                      />
                    </div>
                  </div>
                  <ul className="space-y-4 relative z-10">
                    {[
                      language === 'en' ? "Quick results in <strong>less than an hour</strong>" :
                      language === 'es' ? "Resultados rápidos en <strong>menos de una hora</strong>" :
                      "Resultados rápidos em <strong>menos de uma hora</strong>",
                      language === 'en' ? "<strong>Affordable and accessible</strong> from anywhere" :
                      language === 'es' ? "<strong>Asequible y accesible</strong> desde cualquier lugar" :
                      "<strong>Acessível e disponível</strong> de qualquer lugar",
                      language === 'en' ? "<strong>Try on</strong> your color recommendations" :
                      language === 'es' ? "<strong>Prueba</strong> tus recomendaciones de color" :
                      "<strong>Experimente</strong> suas recomendações de cores",
                      language === 'en' ? "<strong>Personalized results</strong> with expert analysis" :
                      language === 'es' ? "<strong>Resultados personalizados</strong> con análisis experto" :
                      "<strong>Resultados personalizados</strong> com análise especializada"
                    ].map((item, index) => (
                      <li key={index} className="flex items-center text-white">
                        <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                        <span dangerouslySetInnerHTML={{ __html: item }}></span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="text-center mt-16">
                <p className="text-xl text-gray-300 mb-8">
                  {language === 'en' ? 'Unlock your true color potential and transform your style with your new color palette' :
                   language === 'es' ? 'Desbloquea tu verdadero potencial de color y transforma tu estilo con tu nueva paleta de colores' :
                   'Desbloqueie seu verdadeiro potencial de cores e transforme seu estilo com sua nova paleta de cores'}
                </p>
                <Link
                  to={language === 'en' ? '/login' : `/${language}/login`}
                  className="bg-primary text-white font-semibold py-4 px-10 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 text-xl inline-block shadow-lg"
                >
                  {language === 'en' ? 'Experience AI Color Analysis Now' :
                   language === 'es' ? 'Experimenta el Análisis de Color por IA Ahora' :
                   'Experimente a Análise de Cores por IA Agora'}
                </Link>
              </div>
            </div>
          </section>

      {/* Pricing Section */}
          <section className="pricing bg-gray-900 py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">
            {language === 'en' ? 'Choose Your Plan' :
             language === 'es' ? 'Elige tu Plan' :
             'Escolha seu Plano'}
          </h2>
          <div className="flex flex-col md:flex-row justify-center items-stretch gap-8">
            {/* Basic Plan */}
            <div 
              className="bg-gray-800 rounded-lg p-8 w-full md:w-72 flex flex-col transition-all duration-300 ease-in-out transform hover:scale-105 cursor-pointer relative"
              onClick={scrollToGetStarted}
            >
              <div className="flex items-center mb-4">
                <h3 className="text-2xl font-semibold text-white">
                  {language === 'en' ? 'Basic Plan' :
                   language === 'es' ? 'Plan Básico' :
                   'Plano Básico'}
                </h3>
                <span className="inline-block border border-green-500 text-green-500 text-xs font-bold px-2 py-1 rounded-full ml-2">
                  {language === 'en' ? 'One-off' :
                   language === 'es' ? 'Único pago' :
                   'Pagamento único'}
                </span>
              </div>
              <div className="flex items-center mb-6">
                <p className="text-4xl font-bold">$15</p>
              </div>
              <ul className="mb-8 flex-grow">
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? 'Color analysis test' :
                   language === 'es' ? 'Prueba de análisis de color' :
                   'Teste de análise de cor'}
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? '3 tailored color palettes' :
                   language === 'es' ? '3 paletas de colores personalizadas' :
                   '3 paletas de cores personalizadas'}
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? '6 pics of you in those colors' :
                   language === 'es' ? '6 fotos tuyas en esos colores' :
                   '6 fotos suas nessas cores'}
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? '50 playground credits' :
                   language === 'es' ? '50 créditos de juego' :
                   '50 créditos de playground'}
                </li>
              </ul>
              <button className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 mt-auto">
                {language === 'en' ? 'Choose Basic' :
                 language === 'es' ? 'Elegir Básico' :
                 'Escolher Básico'}
              </button>
            </div>

            {/* Pro Plan (Highlighted) */}
            <div 
              className="bg-gradient-to-br from-primary to-secondary rounded-lg p-8 w-full md:w-72 flex flex-col transform md:scale-110 shadow-xl transition-all duration-300 ease-in-out hover:scale-115 cursor-pointer z-10 relative"
              onClick={scrollToGetStarted}
            >
              <div className="flex items-center mb-4">
                <h3 className="text-2xl font-semibold text-white">
                  {language === 'en' ? 'Pro Plan' :
                   language === 'es' ? 'Plan Pro' :
                   'Plano Pro'}
                </h3>
                <span className="inline-block border border-white text-white text-xs font-bold px-2 py-1 rounded-full ml-2">
                  {language === 'en' ? 'One-off' :
                   language === 'es' ? 'Único pago' :
                   'Pagamento único'}
                </span>
              </div>
              <div className="flex items-center mb-6">
                <p className="text-4xl font-bold">$21</p>
              </div>
              <ul className="mb-8 flex-grow">
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? 'Color analysis test' :
                   language === 'es' ? 'Prueba de análisis de color' :
                   'Teste de análise de cor'}
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? '5 tailored color palettes' :
                   language === 'es' ? '5 paletas de colores personalizadas' :
                   '5 paletas de cores personalizadas'}
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? '10 pics of you in those colors' :
                   language === 'es' ? '10 fotos tuyas en esos colores' :
                   '10 fotos suas nessas cores'}
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? '150 playground credits' :
                   language === 'es' ? '150 créditos de juego' :
                   '150 créditos de playground'}
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? 'Gold or Silver test' :
                   language === 'es' ? 'Prueba de Oro o Plata' :
                   'Teste de Ouro ou Prata'}
                </li>
              </ul>
              <button className="bg-white text-primary font-semibold py-2 px-4 rounded-full hover:bg-gray-100 transition duration-300 mt-auto">
                {language === 'en' ? 'Choose Pro' :
                 language === 'es' ? 'Elegir Pro' :
                 'Escolher Pro'}
              </button>
            </div>

            {/* Contact Us Section */}
            <div 
              className="bg-gray-800 rounded-lg p-8 w-full sm:w-72 flex flex-col transition-all duration-300 ease-in-out transform hover:scale-105 cursor-pointer relative"
              onClick={() => window.location.href = 'mailto:hi@palettehunt.com'}
            >
              <h3 className="text-2xl font-semibold text-white mb-4">
                {language === 'en' ? 'Enterprise' :
                 language === 'es' ? 'Empresarial' :
                 'Empresarial'}
              </h3>
              <p className="text-lg mb-6">
                {language === 'en' ? 'Tailored to your needs' :
                 language === 'es' ? 'Adaptado a tus necesidades' :
                 'Adaptado às suas necessidades'}
              </p>
              <ul className="mb-8 flex-grow">
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? 'Tailored color analysis solutions' :
                   language === 'es' ? 'Soluciones de análisis de color personalizadas' :
                   'Soluções de análise de cor personalizadas'}
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? 'Bulk pricing options' :
                   language === 'es' ? 'Opciones de precios por volumen' :
                   'Opções de preços em volume'}
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  {language === 'en' ? 'Customised features' :
                   language === 'es' ? 'Características personalizadas' :
                   'Recursos personalizados'}
                </li>
              </ul>
              <button className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 mt-auto">
                {language === 'en' ? 'Contact Us' :
                 language === 'es' ? 'Contáctanos' :
                 'Entre em Contato'}
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq bg-gray-900 py-12 sm:py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-12">
            {language === 'en' ? 'Frequently Asked Questions' :
             language === 'es' ? 'Preguntas Frecuentes' :
             'Perguntas Frequentes'}
          </h2>
          <div className="grid md:grid-cols-2 gap-6 justify-items-center">
            <div className="max-w-md">
              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">
                  {language === 'en' ? 'How does Palette Hunt work?' :
                   language === 'es' ? '¿Cómo funciona Palette Hunt?' :
                   'Como funciona o Palette Hunt?'}
                </summary>
                <p className="text-gray-300 text-left">
                  {language === 'en' ? 'We use the most advanced AI vision algorithms to analyze your photos and generate personalized color palettes that complement your unique features and style preferences.' :
                   language === 'es' ? 'Utilizamos los algoritmos de visión de IA más avanzados para analizar tus fotos y generar paletas de colores personalizadas que complementan tus características únicas y preferencias de estilo.' :
                   'Usamos os algoritmos de visão de IA mais avançados para analisar suas fotos e gerar paletas de cores personalizadas que complementam suas características únicas e preferências de estilo.'}
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">
                  {language === 'en' ? 'How long does it take to get results?' :
                   language === 'es' ? '¿Cuánto tiempo tarda en obtener resultados?' :
                   'Quanto tempo leva para obter resultados?'}
                </summary>
                <p className="text-gray-300 text-left">
                  {language === 'en' ? 'You will typically receive your results within 1h. If the results are taking more than 2h, please send us an email at ' :
                   language === 'es' ? 'Normalmente recibirás tus resultados en 1 hora. Si los resultados tardan más de 2 horas, envíanos un correo electrónico a ' :
                   'Você normalmente receberá seus resultados em 1 hora. Se os resultados demorarem mais de 2 horas, envie-nos um e-mail para '}
                  <a href="mailto:hi@palettehunt.com" className=""><strong>hi@palettehunt.com</strong></a>
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">
                  {language === 'en' ? 'Will the quality of the results be good?' :
                   language === 'es' ? '¿Será buena la calidad de los resultados?' :
                   'A qualidade dos resultados será boa?'}
                </summary>
                <p className="text-gray-300 text-left">
                  {language === 'en' ? 'Yes, we can assure you that the quality of the results will be the best you can get in the market. But you need to provide us with good quality photos to maximize the quality of the results.' :
                   language === 'es' ? 'Sí, podemos asegurarte que la calidad de los resultados será la mejor que puedes obtener en el mercado. Pero necesitas proporcionarnos fotos de buena calidad para maximizar la calidad de los resultados.' :
                   'Sim, podemos garantir que a qualidade dos resultados será a melhor que você pode obter no mercado. Mas você precisa nos fornecer fotos de boa qualidade para maximizar a qualidade dos resultados.'}
                </p>
              </details>
              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">
                  {language === 'en' ? 'Do I need to upload many pictures?' :
                   language === 'es' ? '¿Necesito subir muchas fotos?' :
                   'Preciso fazer upload de muitas fotos?'}
                </summary>
                <p className="text-gray-300 text-left">
                  {language === 'en' ? 'For optimal results, we recommend uploading 8-10 photos that showcase different angles, lighting conditions, and outfits. This will determine the quality of the results!' :
                   language === 'es' ? 'Para obtener resultados óptimos, recomendamos subir de 8 a 10 fotos que muestren diferentes ángulos, condiciones de iluminación y atuendos. ¡Esto determinará la calidad de los resultados!' :
                   'Para obter resultados ideais, recomendamos o upload de 8 a 10 fotos que mostrem diferentes ângulos, condições de iluminação e roupas. Isso determinará a qualidade dos resultados!'}
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">
                  {language === 'en' ? 'Can I keep the generated images?' :
                   language === 'es' ? '¿Puedo conservar las imágenes generadas?' :
                   'Posso manter as imagens geradas?'}
                </summary>
                <p className="text-gray-300 text-left">
                  {language === 'en' ? 'Absolutely! You own all the images generated by Palette Hunt. Both for the playground and for the color analysis.' :
                   language === 'es' ? '¡Absolutamente! Eres dueño de todas las imágenes generadas por Palette Hunt. Tanto para el área de juegos como para el análisis de color.' :
                   'Absolutamente! Você é proprietário de todas as imagens geradas pelo Palette Hunt. Tanto para o playground quanto para a análise de cores.'}
                </p>
              </details>

                  </div>
            <div className="max-w-md">
              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">
                  {language === 'en' ? 'Can I use Palette Hunt on mobile devices?' :
                   language === 'es' ? '¿Puedo usar Palette Hunt en dispositivos móviles?' :
                   'Posso usar o Palette Hunt em dispositivos móveis?'}
                </summary>
                <p className="text-gray-300 text-left">
                  {language === 'en' ? 'Yes, no problem! Palette Hunt works on either mobile or desktop. Feel free to use the device you are most comfortable with!' :
                   language === 'es' ? '¡Sí, sin problema! Palette Hunt funciona tanto en móviles como en computadoras de escritorio. ¡Siéntete libre de usar el dispositivo con el que te sientas más cómodo!' :
                   'Sim, sem problemas! O Palette Hunt funciona tanto em dispositivos móveis quanto em computadores. Sinta-se à vontade para usar o dispositivo com o qual você se sente mais confortável!'}
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">
                  {language === 'en' ? 'How accurate are the color recommendations?' :
                   language === 'es' ? '¿Qué tan precisas son las recomendaciones de color?' :
                   'Quão precisas são as recomendações de cores?'}
                </summary>
                <p className="text-gray-300 text-left">
                  {language === 'en' ? 'Our color recommendation process has been thoroughly tested to ensure our results are the best you can get. Our satisfaction rate is extremely high among our users.' :
                   language === 'es' ? 'Nuestro proceso de recomendación de colores ha sido probado exhaustivamente para asegurar que nuestros resultados sean los mejores que puedas obtener. Nuestra tasa de satisfacción es extremadamente alta entre nuestros usuarios.' :
                   'Nosso processo de recomendação de cores foi testado minuciosamente para garantir que nossos resultados sejam os melhores que você pode obter. Nossa taxa de satisfação é extremamente alta entre nossos usuários.'}
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">
                  {language === 'en' ? 'Which type of photos will I get?' :
                   language === 'es' ? '¿Qué tipo de fotos recibiré?' :
                   'Que tipo de fotos eu vou receber?'}
                </summary>
                <p className="text-gray-300 text-left">
                  {language === 'en' ? 'For each color palette you get, you will receive 2 images of you in that color palette. One will have a white background, like in a studio, and one will have a background that matches the color theme.' :
                   language === 'es' ? 'Por cada paleta de colores que obtengas, recibirás 2 imágenes tuyas en esa paleta de colores. Una tendrá un fondo blanco, como en un estudio, y la otra tendrá un fondo que coincida con el tema de color.' :
                   'Para cada paleta de cores que você receber, você terá 2 imagens suas nessa paleta de cores. Uma terá um fundo branco, como em um estúdio, e a outra terá um fundo que combina com o tema de cores.'}
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">
                  {language === 'en' ? 'What happens if I don\'t like the results?' :
                   language === 'es' ? '¿Qué sucede si no me gustan los resultados?' :
                   'O que acontece se eu não gostar dos resultados?'}
                </summary>
                <p className="text-gray-300 text-left">
                  {language === 'en' ? 'Quality is our top priority. At this time, we cannot offer full refunds as every color analysis means a significant cost per user. We are sure though, we will find a solution for you. We are always available at ' :
                   language === 'es' ? 'La calidad es nuestra máxima prioridad. En este momento, no podemos ofrecer reembolsos completos ya que cada análisis de color significa un costo significativo por usuario. Sin embargo, estamos seguros de que encontraremos una solución para ti. Siempre estamos disponibles en ' :
                   'A qualidade é nossa prioridade máxima. No momento, não podemos oferecer reembolsos totais, pois cada análise de cor representa um custo significativo por usuário. No entanto, temos certeza de que encontraremos uma solução para você. Estamos sempre disponíveis em '}
                  <a href="mailto:hi@palettehunt.com" className=""><strong>hi@palettehunt.com</strong></a>
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">
                  {language === 'en' ? 'Will my photos be shared with anyone?' :
                   language === 'es' ? '¿Se compartirán mis fotos con alguien?' :
                   'Minhas fotos serão compartilhadas com alguém?'}
                </summary>
                <p className="text-gray-300 text-left">
                  {language === 'en' ? 'No. We just use the photos to train our AI that no one else has access to. Every uploaded picture you upload gets deleted after a week.' :
                   language === 'es' ? 'No. Solo usamos las fotos para entrenar nuestra IA a la que nadie más tiene acceso. Cada imagen que subes se elimina después de una semana.' :
                   'Não. Usamos as fotos apenas para treinar nossa IA, à qual ninguém mais tem acesso. Cada imagem que você carrega é excluída após uma semana.'}
                </p>
              </details>
              </div>
            </div>
          </div>
        </section>

        </main>
        <Footer />
      </div>
    </>
  );
};

export default ColorAnalysisLocation;