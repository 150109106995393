import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

const translations = {
  en: {
    welcomeBack: "Welcome",
    discoverPalette: "Discover your perfect color palette and get your color analysis now",
    termsAgreement: "By logging in, you agree to our",
    termsOfService: "Terms of Service",
    and: "and",
    privacyPolicy: "Privacy Policy",
    whyChoose: "Why Choose Palette Hunt?",
    features: [
      "Color analysis in less than 1h",
      "Personalized style recommendations",
      "Try-on of recommended colors",
      "Interactive color playground",
    ],
  },
  es: {
    welcomeBack: "Bienvenido",
    discoverPalette: "Descubre tu paleta de colores perfecta y obtén tu análisis de color ahora",
    termsAgreement: "Al iniciar sesión, aceptas nuestros",
    termsOfService: "Términos de servicio",
    and: "y",
    privacyPolicy: "Política de privacidad",
    whyChoose: "¿Por qué elegir Palette Hunt?",
    features: [
      "Análisis de color en menos de 1h",
      "Recomendaciones de estilo personalizadas",
      "Prueba de colores recomendados",
      "Playground de colores interactivo",
    ],
  },
  pt: {
    welcomeBack: "Bem-vindo",
    discoverPalette: "Descubra sua paleta de cores perfeita e obtenha seu análise de cor agora",
    termsAgreement: "Ao fazer login, você concorda com nossos",
    termsOfService: "Termos de serviço",
    and: "e",
    privacyPolicy: "Política de privacidade",
    whyChoose: "Por que escolher o Palette Hunt?",
    features: [
      "Análise de cores em menos de 1h",
      "Recomendações de estilo personalizadas",
      "Experimentação das cores recomendadas",
      "Playground de cores interativo",
    ],
  },
};

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const language = location.pathname.startsWith('/es/') ? 'es' : 
                   location.pathname.startsWith('/pt/') ? 'pt' : 'en';
  const t = translations[language];
  const isNativeMobile = Capacitor.isNativePlatform();

  useEffect(() => {
    if (isNativeMobile) {
      GoogleAuth.initialize({
        clientId: process.env.REACT_APP_IOS_CLIENT_ID,
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      });
    }
  }, [isNativeMobile]);

  const handleGoogleSuccess = async (response) => {
    setIsLoading(true);
    try {
      let idToken;
      if (isNativeMobile) {
        idToken = response.authentication.idToken;
      } else {
        idToken = response.credential;
      }

      const verifyResponse = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`, 
        { idToken },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (verifyResponse.data.valid) {
        localStorage.setItem('token', verifyResponse.data.token);
        if (verifyResponse.data.hasPaid) {
          navigate(verifyResponse.data.nextPage);
        } else {
          localStorage.setItem('googleCredential', idToken);
          navigate(language === 'en' ? '/payment' : `/${language}/payment`);
        }
      } else {
        throw new Error('Invalid token');
      }
    } catch (error) {
      console.error('Login error:', error);
      // Handle error (show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleError = (error) => {
    console.error('Google Login Failed:', error);
    // Handle login failure (show error message to user)
  };

  const getTextColor = () => {
    return isNativeMobile ? 'text-black' : 'text-white';
  };

  const getGradientTextColor = () => {
    return isNativeMobile ? 'text-black' : 'bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text';
  };

  const getLinkColor = () => {
    return isNativeMobile ? 'text-pink-500 hover:text-pink-700' : 'text-primary hover:underline';
  };

  return (
    <div className={`${isNativeMobile ? 'bg-pink-100' : 'bg-gradient-to-br from-gray-900 to-gray-800'} ${getTextColor()} min-h-screen flex items-center justify-center p-4`}>
      <div className={`max-w-md w-full ${isNativeMobile ? 'bg-white' : 'bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg'} rounded-3xl shadow-2xl overflow-hidden`}>
        <div className="p-8">
          <Link to={language === 'en' ? '/' : `/${language}`} className="flex items-center justify-center mb-8">
            <img src="/logo.png" alt="Palette Hunt Logo" className="h-16 w-auto" />
          </Link>
          
          <h2 className={`text-4xl font-bold mb-2 text-center ${getGradientTextColor()}`}>{t.welcomeBack}</h2>
          <p className={`${getTextColor()} text-center mb-8 max-w-xs mx-auto`}>{t.discoverPalette}</p>
          
          <div className="mb-8 flex flex-col items-center">
            {isLoading ? (
              <ClipLoader color={isNativeMobile ? "#000000" : "#ffffff"} loading={isLoading} size={50} />
            ) : isNativeMobile ? (
              <button
                onClick={() => GoogleAuth.signIn().then(handleGoogleSuccess).catch(handleGoogleError)}
                className="flex items-center justify-center bg-blue-500 text-white font-semibold py-2 px-4 rounded-full shadow-md hover:bg-blue-600 transition duration-300 ease-in-out"
              >
                <img src="/google.png" alt="Google" className="w-6 h-6 mr-2" />
                Sign in with Google
              </button>
            ) : (
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onSuccess={handleGoogleSuccess}
                useOneTap={false}
                cookiePolicy={'single_host_origin'}
                size="large"
                text="signin_with"
                shape="pill"
                theme="filled_blue"
                width="300px"
              />
            )}
            <p className={`text-xs ${isNativeMobile ? 'text-gray-600' : 'text-gray-400'} mt-4 text-center max-w-xs`}>
              {t.termsAgreement}{' '}
              <Link to={language === 'en' ? '/terms-and-conditions' : `/${language}/terms-and-conditions`} className={getLinkColor()}>{t.termsOfService}</Link>{' '}
              {t.and}{' '}
              <Link to={language === 'en' ? '/privacy-policy' : `/${language}/privacy-policy`} className={getLinkColor()}>{t.privacyPolicy}</Link>.
            </p>
          </div>
          
          {!isNativeMobile && (
            <div className="space-y-4">
              <h3 className={`text-xl font-semibold mb-4 text-center ${getTextColor()}`}>{t.whyChoose}</h3>
              {t.features.map((feature, index) => (
                <div key={index} className="flex items-center mb-3">
                  <svg className={`w-5 h-5 mr-3 ${isNativeMobile ? 'text-green-500' : 'text-green-400'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className={isNativeMobile ? 'text-gray-800' : 'text-gray-200'}>{feature}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;