import React from 'react';
import { Link, useParams } from 'react-router-dom';

const translations = {
  en: {
    title: "Privacy Policy",
    intro: "At Palette Hunt, we prioritize your privacy and the protection of your data. All information collected, both personal and non-personal, is safeguarded with the highest standards.",
    commitment: "Palette Hunt is committed to maintaining the trust and confidence of our users. We want you to understand when and why we collect your personal information, how we use it, the conditions under which we may disclose it to others, and how we keep it secure.",
    keyPoints: "Key Points:",
    keyPointsList: [
      "We delete all user photos after one week",
      "We only keep your email address for identification purposes",
      "We do not share your personal information with third parties",
      "We use the photos you provide to train our AI model, but this data is only accessible to our internal team"
    ],
    infoCollection: "Information Collection and Use",
    infoCollectionText: "We collect your email address for account identification and communication purposes. The photos you upload are used solely for color analysis and are automatically deleted after one week.",
    dataSecurity: "Data Security",
    dataSecurityText: "We implement robust security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.",
    yourRights: "Your Rights",
    yourRightsText: "You have the right to access, correct, or delete your personal information at any time. Please contact us if you wish to exercise these rights.",
    contact: "For any questions or concerns regarding our privacy practices, please contact us at hi@palettehunt.com",
    returnHome: "Return to Home"
  },
  es: {
    title: "Política de Privacidad",
    intro: "En Palette Hunt, priorizamos su privacidad y la protección de sus datos. Toda la información recopilada, tanto personal como no personal, está protegida con los más altos estándares.",
    commitment: "Palette Hunt se compromete a mantener la confianza de nuestros usuarios. Queremos que entienda cuándo y por qué recopilamos su información personal, cómo la usamos, las condiciones bajo las cuales podemos divulgarla a otros y cómo la mantenemos segura.",
    keyPoints: "Puntos Clave:",
    keyPointsList: [
      "Eliminamos todas las fotos de los usuarios después de una semana",
      "Solo guardamos su dirección de correo electrónico para fines de identificación",
      "No compartimos su información personal con terceros",
      "Usamos las fotos que proporciona para entrenar nuestro modelo de IA, pero estos datos solo son accesibles para nuestro equipo interno"
    ],
    infoCollection: "Recopilación y Uso de Información",
    infoCollectionText: "Recopilamos su dirección de correo electrónico para fines de identificación de cuenta y comunicación. Las fotos que sube se utilizan únicamente para el análisis de color y se eliminan automáticamente después de una semana.",
    dataSecurity: "Seguridad de Datos",
    dataSecurityText: "Implementamos medidas de seguridad robustas para proteger su información personal contra acceso, alteración, divulgación o destrucción no autorizados.",
    yourRights: "Sus Derechos",
    yourRightsText: "Tiene derecho a acceder, corregir o eliminar su información personal en cualquier momento. Póngase en contacto con nosotros si desea ejercer estos derechos.",
    contact: "Para cualquier pregunta o inquietud sobre nuestras prácticas de privacidad, contáctenos en hi@palettehunt.com",
    returnHome: "Volver al Inicio"
  },
  pt: {
    title: "Política de Privacidade",
    intro: "Na Palette Hunt, priorizamos sua privacidade e a proteção de seus dados. Todas as informações coletadas, pessoais e não pessoais, são protegidas com os mais altos padrões.",
    commitment: "A Palette Hunt está comprometida em manter a confiança de nossos usuários. Queremos que você entenda quando e por que coletamos suas informações pessoais, como as usamos, as condições sob as quais podemos divulgá-las a terceiros e como as mantemos seguras.",
    keyPoints: "Pontos Principais:",
    keyPointsList: [
      "Excluímos todas as fotos dos usuários após uma semana",
      "Mantemos apenas seu endereço de e-mail para fins de identificação",
      "Não compartilhamos suas informações pessoais com terceiros",
      "Usamos as fotos que você fornece para treinar nosso modelo de IA, mas esses dados são acessíveis apenas à nossa equipe interna"
    ],
    infoCollection: "Coleta e Uso de Informações",
    infoCollectionText: "Coletamos seu endereço de e-mail para fins de identificação de conta e comunicação. As fotos que você envia são usadas exclusivamente para análise de cores e são automaticamente excluídas após uma semana.",
    dataSecurity: "Segurança de Dados",
    dataSecurityText: "Implementamos medidas de segurança robustas para proteger suas informações pessoais contra acesso, alteração, divulgação ou destruição não autorizados.",
    yourRights: "Seus Direitos",
    yourRightsText: "Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Entre em contato conosco se desejar exercer esses direitos.",
    contact: "Para quaisquer perguntas ou preocupações sobre nossas práticas de privacidade, entre em contato conosco em hi@palettehunt.com",
    returnHome: "Voltar para a Página Inicial"
  }
};

const PrivacyPolicy = () => {
  const { language = 'en' } = useParams();
  const t = translations[language] || translations.en;

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="max-w-4xl mx-auto px-4 py-16">
        <div className="flex items-center mb-8">
          <img src="/logo.png" alt="Palette Hunt Logo" className="h-10 w-auto mr-3" />
          <span className="text-2xl text-white font-bold">Palette Hunt</span>
        </div>

        <h1 className="text-4xl font-bold mb-8">{t.title}</h1>
        
        <p className="mb-6">{t.intro}</p>
        
        <p className="mb-6">{t.commitment}</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">{t.keyPoints}</h2>
        <ul className="list-disc list-inside mb-6">
          {t.keyPointsList.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">{t.infoCollection}</h2>
        <p className="mb-6">{t.infoCollectionText}</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">{t.dataSecurity}</h2>
        <p className="mb-6">{t.dataSecurityText}</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">{t.yourRights}</h2>
        <p className="mb-6">{t.yourRightsText}</p>
        
        <p className="mt-12">{t.contact}</p>
        
        <div className="mt-12">
          <Link to={language === 'en' ? '/' : `/${language}`} className="text-primary hover:text-secondary">{t.returnHome}</Link>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;