import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const AboutPaletteHunt = () => {
  return (
    <div className="bg-gray-900 min-h-screen text-white">
      <Helmet>
        <title>About Palette Hunt - AI-Powered Color Analysis | Discover Your Perfect Palette</title>
        <meta name="description" content="Learn about Palette Hunt, the revolutionary AI-powered color analysis tool. Discover how we help you find your perfect color palette for fashion and style." />
        <link rel="canonical" href="https://www.palettehunt.com/about-palette-hunt" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "AboutPage",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://www.palettehunt.com/about-palette-hunt"
              },
              "name": "About Palette Hunt",
              "description": "Palette Hunt is an AI-powered color analysis tool that helps you discover your perfect color palette."
            }
          `}
        </script>
      </Helmet>
      
      <div className="container mx-auto px-4 py-12">
        <Link to="/" className="inline-flex items-center mb-8">
          <LazyLoadImage src="/logo.png" alt="Palette Hunt Logo" className="h-12 w-auto sm:h-16" effect="blur" />
          <span className="ml-2 text-2xl font-bold text-white">Palette Hunt</span>
        </Link>

        <motion.h1 
          className="text-4xl sm:text-5xl font-bold mb-8 text-center bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          About Palette Hunt (Palette Hunt)
        </motion.h1>

        <motion.div 
          className="max-w-3xl mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <p className="text-lg mb-6">
            Palette Hunt is a revolutionary AI-powered color analysis tool designed to help you discover your perfect color palette. Our mission is to empower individuals to make confident style choices by understanding the colors that complement their unique features.
          </p>

          <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
          <p className="mb-6">
            Founded in 2024, Palette Hunt was born from a simple idea: everyone deserves to feel confident in the colors they wear. Our team of fashion enthusiasts, color experts, and AI specialists came together to create a tool that makes professional color analysis accessible to all.
          </p>

          <h2 className="text-2xl font-semibold mb-4">How Palette Hunt Works</h2>
          <ol className="list-decimal list-inside mb-6">
            <li className="mb-2">Upload your photos</li>
            <li className="mb-2">Our AI analyzes your unique features</li>
            <li className="mb-2">Receive your personalized color palette</li>
            <li>See yourself in your most flattering colors</li>
          </ol>

          <h2 className="text-2xl font-semibold mb-4">Why Choose Palette Hunt?</h2>
          <ul className="list-disc list-inside mb-6">
            <li className="mb-2">AI-powered accuracy</li>
            <li className="mb-2">Personalized recommendations</li>
            <li className="mb-2">Virtual try-on technology</li>
            <li className="mb-2">Affordable and accessible</li>
            <li>Continuous learning and improvement</li>
          </ul>

          <p className="text-lg mb-8">
            Whether you're a fashion enthusiast, a professional looking to refine your image, or someone who simply wants to feel more confident in their color choices, Palette Hunt is here to guide you on your color journey.
          </p>

          <div className="text-center">
            <Link 
              to="/login" 
              className="bg-primary text-white font-semibold py-3 px-8 rounded-full hover:bg-opacity-90 transition duration-300"
            >
              Start Your Color Analysis
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutPaletteHunt;