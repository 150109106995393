import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import { CheckCircle2, Globe, ChevronDown } from 'lucide-react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const translations = {
  pt: {
    title: "Palette Hunt - Análise de Cores com IA para Moda e Estilo",
    description: "Descubra sua paleta de cores perfeita com a análise impulsionada por IA da Palette Hunt. Transforme seu estilo, experimente looks virtuais e obtenha recomendações personalizadas. Desbloqueie seu verdadeiro potencial de moda hoje!",
    keywords: "análise de cores, paleta, paleta de cores, palette hunt, palettehunt, estilismo com IA, paleta de cores pessoal, tecnologia de moda, recomendações de estilo",
    ogTitle: "Palette Hunt - Análise de Cores Impulsionada por IA",
    ogDescription: "Descubra sua paleta de cores perfeita com análise impulsionada por IA.",
    getStarted: "Começar",
    contact: "Contato",
    signIn: "Entrar",
    discover: "Descubra",
    yourPerfect: "Sua Perfeita",
    colorPalette: "Paleta de Cores",
    findYourShades: "Encontre seus tons mais favoráveis com nossa análise de cores impulsionada por IA. Faça o upload de suas fotos para se ver transformado em suas cores perfeitas.",
    imagesCreated: "+15.000 imagens criadas",
  }
};

const PricingComponent = ({ isMobile }) => {
  return (
    <div className="w-full py-12 mt-20">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-center items-start">
          <div className="w-full md:w-1/3 mb-8 md:mb-0 text-center">
            <h2 className={`text-xl font-bold mb-4 text-white ${!isMobile ? 'mr-10' : ''}`}>Teste de cores imbatível:</h2>     
            <ul className={`space-y-3 ${isMobile ? 'mx-auto' : 'inline-block'} text-left`}>
              {['Resultados em menos de 1h', '10x mais barato que um estilista', 'Veja-se realmente nas cores'].map((item, index) => (
                <li key={index} className={`flex items-center text-gray-200 ${isMobile ? 'justify-center' : ''}`}>
                  <CheckCircle2 className="text-green-500 mr-2 flex-shrink-0" size={20} />
                  <span className={isMobile ? 'text-center' : ''}>
                    {item === 'Resultados em menos de 1h' ? (
                      <>Resultados em <strong>menos de 1h</strong></>
                    ) : item === '10x mais barato que um estilista' ? (
                      <><strong>10x mais barato</strong> que um estilista</>
                    ) : (
                      <>Realmente <strong>veja-se</strong> nas cores</>
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          <div className="w-full md:w-1/3 text-center mb-8 md:mb-0">
            {/* <div className="text-xl font-semibold text-secondary mb-2">Teste de Cores</div> */}
            <div className="text-center">
        <div className="flex justify-center items-center mb-2">
          {[...Array(5)].map((_, index) => (
            <svg key={index} className="w-6 h-6 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
          ))}
        </div>
        <p className="text-2xl font-semibold text-white mb-4">4.9 de 5</p>
      </div>
            <div className="w-20 h-1 bg-secondary mx-auto mb-4"></div>
            <p className="text-gray-200 mb-6"><strong>Amado pelos nossos usuários</strong></p>
            <button className="bg-secondary text-gray-900 font-semibold py-2 px-6 rounded-full hover:bg-opacity-90 transition duration-300">
              <Link to="/pt/login" className="bg-secondary text-gray-900 font-semibold py-2 px-6 rounded-full hover:bg-opacity-90 transition duration-300">
                Começar
              </Link>
            </button>
          </div>

          <div className="w-full md:w-1/3 text-center">
            <h2 className="text-xl font-bold mb-4 text-white">Cada pacote inclui:</h2>
            <ul className={`space-y-4 ${isMobile ? 'mx-auto' : 'inline-block'} text-left ${isMobile ? '' : 'pl-8'}`}>
              {['Seu guia de análise de cores', 'Imagens de IA com a mais alta qualidade', 'Acesso ao playground para experimentar novos estilos'].map((item, index) => (
                <li key={index} className={`flex items-center text-gray-200 ${isMobile ? 'justify-center' : ''}`}>
                  <CheckCircle2 className="text-green-500 mr-2 flex-shrink-0" size={20} />
                  <span className={isMobile ? 'text-center' : ''}>
                    {item === 'Seu guia de análise de cores' ? (
                      <>Seu <strong>guia de análise de cores</strong></>
                    ) : item === 'Imagens de IA com a mais alta qualidade' ? (
                      <><strong>Imagens de IA</strong> com a mais alta qualidade</>
                    ) : (
                      <><strong>Playground de fotos</strong> para experimentar novos estilos</>
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColorNugget = ({ colors, isMobile }) => {
    return (
      <motion.div
        className={`${isMobile ? 'w-20 mt-0.5' : 'w-5'} ${isMobile ? 'h-36' : 'h-48'} rounded-full overflow-hidden shadow-lg ml-2`}
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        {colors.map((color, index) => (
          <div key={index} className="h-1/3" style={{ backgroundColor: color }} />
        ))}
      </motion.div>
    );
  };

const LandingPage = () => {
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const getStartedRef = useRef(null);
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth < 1024);
  const [language, setLanguage] = useState('pt');
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const languageDropdownRef = useRef(null);

  const t = translations[language];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    setIsLanguageDropdownOpen(false);
    if (lang === 'es') {
      navigate('/es');
    } else if (lang === 'pt') {
      navigate('/pt');
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
        setIsLanguageDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const updateArrows = () => {
    if (containerRef.current && svgRef.current) {
      const container = containerRef.current;
      const svg = svgRef.current;

      svg.innerHTML = '';

      const drawArrow = (
        startEl,
        endEl,
        startXPercent,
        startYPercent,
        endXPercent,
        endYPercent,
        curveFactor = 0.5,
        color = '#FFA500',
        addText = false,
        textContent = '',
        textOffsetX = 0,
        textOffsetY = 0
      ) => {
        if (!startEl || !endEl) return;

        const startRect = startEl.getBoundingClientRect();
        const endRect = endEl.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        const startX = startRect.left + startRect.width * startXPercent - containerRect.left;
        const startY = startRect.top + startRect.height * startYPercent - containerRect.top;
        const endX = endRect.left + endRect.width * endXPercent - containerRect.left;
        const endY = endRect.top + endRect.height * endYPercent - containerRect.top;

        const midX = (startX + endX) / 2;
        const midY = (startY + endY) / 2;

        // Calculate control point for a more pronounced curve
        const dx = endX - startX;
        const dy = endY - startY;
        const normalX = -dy;
        const normalY = dx;
        const distance = Math.sqrt(dx * dx + dy * dy);

        const controlX = midX + (normalX / distance) * curveFactor * 100;
        const controlY = midY + (normalY / distance) * curveFactor * 100;

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttribute('d', `M${startX},${startY} Q${controlX},${controlY} ${endX},${endY}`);
        path.setAttribute('fill', 'none');
        path.setAttribute('stroke', color);
        path.setAttribute('stroke-width', '2');
        svg.appendChild(path);

        // Improved arrowhead
        const arrowSize = 10;
        const arrowWidth = 6;
        const arrowAngle = Math.atan2(endY - controlY, endX - controlX);
        const arrowhead = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        arrowhead.setAttribute(
          'd',
          `M${endX},${endY} 
              L${endX - arrowSize * Math.cos(arrowAngle - Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle - Math.PI / 6)}
              L${endX - (arrowSize - arrowWidth / 2) * Math.cos(arrowAngle)},${endY - (arrowSize - arrowWidth / 2) * Math.sin(arrowAngle)}
              L${endX - arrowSize * Math.cos(arrowAngle + Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle + Math.PI / 6)}
              Z`
        );
        arrowhead.setAttribute('fill', color);
        svg.appendChild(arrowhead);

        if (addText) {
          // Add text near the arrow
          const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          text.setAttribute('x', endX + textOffsetX);
          text.setAttribute('y', endY + textOffsetY);
          text.setAttribute('fill', color);
          text.setAttribute('font-family', '"Nerko One", cursive');
          text.setAttribute('font-size', '17px');
          text.setAttribute('font-weight', 'normal');
          text.textContent = textContent;
          svg.appendChild(text);
        }
      };

      const lauritaSmall = container.querySelector('[alt="Palette Hunt Selfie Small 3"]');
      const lauritaLarge = container.querySelector('[alt="Palette Hunt Transformation Large 2"]');
      const olympiaSmall = container.querySelector('[alt="Palette Hunt Selfie Small Olympia 3"]');
      const olympiaLarge = container.querySelector('[alt="Palette Hunt Transformation Large 4"]');
      const olympiaSmall1 = container.querySelector('[alt="Palette Hunt Selfie Small Olympia 1"]');
      const colorPalette = container.querySelector('[alt="Palette Hunt Transformation Large 1');

       // Draw arrows
       drawArrow(
        lauritaSmall,
        lauritaLarge,
        isMobile ? 1.1 : 1.1,
        isMobile ? 0.7 : 0.65,
        isMobile ? 0.7 : 0.7,
        isMobile ? -0.05 : -0.05,
        isMobile ? -0.3 : -0.3,
        '#FFFFFF',
        false
      );

      drawArrow(
        olympiaSmall,
        olympiaLarge,
        1.15,
        0.35,
        isMobile ? 0.75 : 0.75,
        isMobile ? 1.05 : 1.05,
        isMobile ? 0.35 : 0.35,
        '#FFFFFF',
        false
      );

      // if (!isMobile) {
        // Draw 'Upload some pics' arrow
        const drawUploadArrow = () => {
          if (!olympiaSmall1) return;
          if(isTablet) return;

          const rect = olympiaSmall1.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();
        
          const startX = rect.left - containerRect.left - (isMobile ? 26 : 60);
          const startY = rect.top - containerRect.top + rect.height / 2;
          const endX = rect.left - containerRect.left - (isMobile ? 2 : 5);
          const endY = rect.top - containerRect.top + rect.height / 2;
        
          // Invert the curve by changing the sign of the Y offset
          const controlX = startX - (isMobile ? 10 : 20);
          const controlY = startY + (isMobile ? 20 : 40);
        
          const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
          path.setAttribute('d', `M${isMobile ? startX + 2 : startX},${isMobile ? startY - 18 : startY} Q${controlX},${controlY} ${endX},${endY}`);
          path.setAttribute('fill', 'none');
          path.setAttribute('stroke', '#FFA500');
          path.setAttribute('stroke-width', '2');
          svg.appendChild(path);
        
          // Arrowhead
          const arrowSize = isMobile ? 8 : 10;
          const arrowAngle = Math.atan2(endY - controlY, endX - controlX);
          const arrowhead = document.createElementNS('http://www.w3.org/2000/svg', 'path');
          arrowhead.setAttribute(
            'd',
            `M${endX},${endY} 
                L${endX - arrowSize * Math.cos(arrowAngle - Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle - Math.PI / 6)}
                L${endX - arrowSize * Math.cos(arrowAngle + Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle + Math.PI / 6)}
                Z`
          );
          arrowhead.setAttribute('fill', '#FFA500');
          svg.appendChild(arrowhead);
        
          // Text
          const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          text.setAttribute('x', startX - (isMobile ? 55 : 85));
          text.setAttribute('y', startY - (isMobile ? 44 : 48)); // Adjusted Y position for mobile
          text.setAttribute('fill', '#FFA500');
          text.setAttribute('font-family', '"Nerko One", cursive');
          text.setAttribute('font-size', isMobile ? '14px' : '17px');
          text.setAttribute('font-weight', 'normal');
          text.setAttribute('transform', `rotate(-20, ${startX + (isMobile ? 45 : 90)}, ${startY - (isMobile ? 55 : 90)})`); // Adjusted rotation point for mobile
          text.textContent = 'Envie algumas fotos';
          svg.appendChild(text);
        };

        // Draw 'AI generated' arrow
        const drawAIGeneratedArrow = () => {
          if(isTablet) return;
          if (!colorPalette) return;

          const rect = colorPalette.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          let startX, startY, endX, endY, controlX, controlY;

          if (isMobile) {
            startX = rect.left - containerRect.left + 25;
            startY = rect.top - containerRect.top - 35; // Changed from -20 to -10
            endX = rect.left - containerRect.left + 30;
            endY = rect.top - containerRect.top - 5;
            controlX = startX - 20;
            controlY = startY + 15;
          } else {
            startX = rect.left - containerRect.left - 40;
            startY = rect.top - containerRect.top + rect.height * 0.8;
            endX = rect.left - containerRect.left - 5;
            endY = rect.top - containerRect.top + rect.height * 0.8;
            controlX = startX - 10;
            controlY = startY + 20;
          }

          const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
          path.setAttribute('d', `M${startX},${startY} Q${controlX},${controlY} ${endX},${endY}`);
          path.setAttribute('fill', 'none');
          path.setAttribute('stroke', '#FFA500');
          path.setAttribute('stroke-width', '2');
          svg.appendChild(path);

          // Arrowhead
          const arrowSize = isMobile ? 5 : 10;
          const arrowAngle = Math.atan2(endY - controlY, endX - controlX);
          const arrowhead = document.createElementNS('http://www.w3.org/2000/svg', 'path');
          arrowhead.setAttribute(
            'd',
            `M${endX},${endY} 
              L${endX - arrowSize * Math.cos(arrowAngle - Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle - Math.PI / 6)}
              L${endX - arrowSize * Math.cos(arrowAngle + Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle + Math.PI / 6)}
              Z`
          );
          arrowhead.setAttribute('fill', '#FFA500');
          svg.appendChild(arrowhead);

          // Text
          const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          if (isMobile) {
            text.setAttribute('x', startX - 18);
            text.setAttribute('y', startY - 5); // Changed from -25 to -15
            text.setAttribute('transform', `rotate(-10, ${startX - 30}, ${startY + 20})`);
          } else {
            text.setAttribute('x', startX - 55);
            text.setAttribute('y', startY + 5);
            text.setAttribute('transform', `rotate(-20, ${startX - 50}, ${startY - 5})`);
          }
          text.setAttribute('fill', '#FFA500');
          text.setAttribute('font-family', '"Nerko One", cursive');
          text.setAttribute('font-size', isMobile ? '14px' : '17px');
          text.setAttribute('font-weight', 'normal');
          text.textContent = isMobile ? 'Fotos IA' : 'Gerado por IA';
          svg.appendChild(text);
        };

        drawUploadArrow();
        drawAIGeneratedArrow();
      }
    // }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateArrows();
      window.addEventListener('resize', updateArrows);
    }, 100);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', updateArrows);
    };
  }, [isMobile]);

  const ImageWithFallback = ({ src, alt, className, objectPosition }) => {
    const [error, setError] = useState(false);

    const handleError = (e) => {
      console.error(`Failed to load image: ${src}`);
      setError(true);
    };

    return (
      <div className={`relative ${className} ${!isMobile ? 'transition-transform duration-300 ease-in-out transform hover:scale-110' : ''}`}>
        {!error ? (
          <img
            src={src}
            alt={alt}
            className="w-full h-full object-cover rounded-lg"
            style={{ objectPosition: objectPosition || 'center' }}
            onError={handleError}
          />
        ) : (
          <div className="absolute inset-0 flex items-center justify-center bg-red-500 text-white text-xs p-2 text-center rounded-lg">
            Image failed to load: {src}
          </div>
        )}
      </div>
    );
  };

  const scrollToGetStarted = () => {
    getStartedRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };


  return (
    <>
      <Helmet>
        <title>{t.title}</title>
        <meta name="description" content={t.description} />
        <meta name="keywords" content={t.keywords} />
        <link rel="canonical" href="https://www.palettehunt.com" />
        <meta property="og:title" content={t.ogTitle} />
        <meta property="og:description" content={t.ogDescription} />
        <meta property="og:url" content="https://www.palettehunt.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Palette Hunt",
              "alternateName": "Palette Hunt",
              "url": "https://www.palettehunt.com",
              "description": "AI-powered color analysis, virtual try-on and style recommendations",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://www.palettehunt.com/search?q={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Service",
              "name": "Palette Hunt Color Analysis",
              "description": "AI-powered color analysis and style recommendations",
              "provider": {
                "@type": "Organization",
                "name": "Palette Hunt"
              },
              "areaServed": "Worldwide",
              "serviceType": "Color Analysis"
            }
          `}
        </script>
      </Helmet>
      <div className="bg-gray-900 text-white flex flex-col min-h-screen">
        <header className="w-full p-4">
          <nav className="max-w-7xl mx-auto flex justify-between items-center">
            <div className="flex items-center">
              <img
                src="/logo.png"
                alt="Logo da Palette Hunt"
                className="h-10 w-auto ml-2 mr-2 sm:h-14 sm:ml-3 sm:mr-3"
                effect="blur"
              />
              <span className="hidden sm:block text-xl sm:text-2xl text-white font-bold text-primary">Palette Hunt</span>
            </div>
            <div className="flex-shrink-0 pr-4 sm:pr-32 flex items-center">
              <div className="relative mr-4" ref={languageDropdownRef}>
                <button
                  onClick={() => setIsLanguageDropdownOpen(!isLanguageDropdownOpen)}
                  className="text-white hover:text-primary transition duration-300 flex items-center"
                >
                  <Globe className="mr-1" size={20} />
                  <span className="uppercase mr-1">{language}</span>
                  <ChevronDown size={16} />
                </button>
                {isLanguageDropdownOpen && (
                  <div className="absolute top-full left-0 mt-2 w-24 bg-gray-800 rounded-md shadow-lg z-50">
                    <ul>
                      <li>
                        <button
                          onClick={() => changeLanguage('en')}
                          className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700"
                        >
                          English
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => changeLanguage('es')}
                          className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700"
                        >
                          Español
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => changeLanguage('pt')}
                          className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700"
                        >
                          Português
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {!isMobile && (
                <a
                  href="mailto:hi@palettehunt.com"
                  className="text-white hover:text-primary transition duration-300 mr-0 sm:mr-16 md:mr-8"
                >
                  {t.contact}
                </a>
              )}
              <Link
                to="/pt/login"
                className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 text-sm sm:text-base"
              >
                {t.signIn}
              </Link>
            </div>
          </nav>
        </header>
        <main className="flex-grow">
          <section className="hero max-w-7xl mx-auto px-4 pt-12 sm:pt-12 pb-12">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0 text-center md:text-left">
                <div
                  className="inline-block mb-3 bg-gradient-to-r from-primary to-secondary rounded-full px-4 py-1 sm:px-5 sm:py-2 text-xs sm:text-sm font-bold text-white shadow-lg transform hover:scale-105 transition-transform duration-300"
                  style={{ zIndex: 20 }}
                >
                  <span className="mr-1 sm:mr-2 text-base sm:text-lg">🎨</span>
                  {t.imagesCreated}
                </div>
                <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-4 sm:mb-8 text-center md:text-left">
                  <span className="block mb-2">{t.discover}</span>
                  {t.yourPerfect}
                  <span className="block mt-2">
                    {t.colorPalette}
                  </span>
                </h1>
                <h2 className="text-lg sm:text-xl text-gray-300 mb-6 sm:mb-10 max-w-sm mx-auto md:mx-0 md:max-w-md">
                  {t.findYourShades}
                </h2>
                <Link
                  to="/pt/login"
                  ref={getStartedRef}
                  className="bg-primary text-white font-semibold py-2 px-4 sm:py-3 sm:px-6 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 text-lg sm:text-xl inline-block mx-auto md:mx-0 mb-12 md:mb-0"
                >
                  {t.getStarted}
                </Link>
              </div>
              <div className="md:w-1/2 flex items-center justify-center px-4 md:px-0">
                <div ref={containerRef} className={`relative w-full ${isTablet ? 'md:h-[400px]' : 'md:h-[600px]'} mb-16 md:mb-0`}>
                  <svg ref={svgRef} className="absolute top-0 left-0 w-full h-full text-secondary pointer-events-none z-10" />
                  {/* First row of small images */}
                  <div className="flex justify-center mb-2 md:mb-3">
                    <div className="flex space-x-2">
                      <ImageWithFallback 
                        src="/laurita.jpg" 
                        alt="Palette Hunt Selfie Small 1" 
                        className={`w-12 h-12 ${isTablet ? 'md:w-14 md:h-14' : 'md:w-16 md:h-16'} object-cover rounded-lg`} 
                        objectPosition="center 30%" 
                      />
                      <ImageWithFallback 
                        src="/laurita4.jpg" 
                        alt="Palette Hunt Selfie Small 2" 
                        className={`w-12 h-12 ${isTablet ? 'md:w-14 md:h-14' : 'md:w-16 md:h-16'} object-cover rounded-lg`} 
                      />
                      <ImageWithFallback 
                        src="/laurita-6.jpg" 
                        alt="Palette Hunt Selfie Small 3" 
                        className={`w-12 h-12 ${isTablet ? 'md:w-14 md:h-14' : 'md:w-16 md:h-16'} object-cover rounded-lg`} 
                        objectPosition="center 30%" 
                      />
                    </div>
                  </div>
                  {/* Large images with arrow */}
                  <div className="flex justify-center mt-6 mb-2 md:mb-3">
                    <div className="flex space-x-2 items-start">
                      <ImageWithFallback 
                        src="/colorPalette.jpeg" 
                        alt="Palette Hunt Transformation Large 1" 
                        className={`${isTablet ? 'md:w-36 md:h-36' : 'md:w-48 md:h-48'} object-cover rounded-lg`} 
                      />
                      <div className="relative">
                        <ImageWithFallback 
                          src="/sunny-yellow.webp" 
                          alt="Palette Hunt Transformation Large 2" 
                          className={`${isTablet ? 'md:w-36 md:h-36' : 'md:w-48 md:h-48'} object-cover rounded-lg`} 
                        />
                        {!isMobile && (
                          <div className="absolute top-0 left-0">
                            {/* Golden arrow and 'AI generated' text */}
                          </div>
                        )}
                      </div>
                      <ColorNugget colors={['#FFD700', '#E2725B', '#8B4513']} isMobile={isMobile} />
                    </div>
                  </div>
                  {/* Second set of large images */}
                  <div className={`flex justify-center mb-2 md:mb-3 ${isMobile ? 'mt-4' : ''}`}>
                    <div className="flex space-x-2 items-start">
                      <ImageWithFallback 
                        src="/olympia-ai-2.jpeg" 
                        alt="Palette Hunt Transformation Large 3" 
                        className={`${isTablet ? 'md:w-36 md:h-36' : 'md:w-48 md:h-48'} object-cover rounded-lg`} 
                      />
                      <ImageWithFallback 
                        src="/olympia-ai-1.jpeg" 
                        alt="Palette Hunt Transformation Large 4" 
                        className={`${isTablet ? 'md:w-36 md:h-36' : 'md:w-48 md:h-48'} object-cover rounded-lg`} 
                      />
                      <ColorNugget colors={['#80CCFF', '#FFB3B3', '#B266FF']} isMobile={isMobile} />
                    </div>
                  </div>
                  {/* Second row of small images */}
                  <div className={`flex justify-center ${isMobile ? 'mt-6 md:mt-6' : 'mt-2 md:mt-6'}`}>
                    <div className="flex space-x-2 items-end">
                      <div className="relative">
                        <ImageWithFallback 
                          src="/olympia-small-1.jpg" 
                          alt="Palette Hunt Selfie Small Olympia 1" 
                          className={`w-12 h-12 ${isTablet ? 'md:w-14 md:h-14' : 'md:w-16 md:h-16'} object-cover rounded-lg`} 
                        />
                        {!isMobile && (
                          <div className="absolute bottom-0 left-0">
                            {/* Golden arrow and 'Upload some pics' text */}
                          </div>
                        )}
                      </div>
                      <ImageWithFallback 
                        src="/olympia-small-2.jpg" 
                        alt="Palette Hunt Selfie Small Olympia 2" 
                        className={`w-12 h-12 ${isTablet ? 'md:w-14 md:h-14' : 'md:w-16 md:h-16'} object-cover rounded-lg`} 
                        objectPosition="center 50%" 
                      />
                      <div className="relative">
                        <ImageWithFallback 
                          src="/olympia-small-3.jpg" 
                          alt="Palette Hunt Selfie Small Olympia 3" 
                          className={`w-12 h-12 ${isTablet ? 'md:w-14 md:h-14' : 'md:w-16 md:h-16'} object-cover rounded-lg`} 
                        />
                        {!isMobile && (
                          <div className="absolute bottom-0 right-0">
                            {/* White arrow to large image */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* New Rating and Image Count */}
          
          {/* New Pricing Component */}
          <section className="pricing">
            <div className={`max-w-7xl mx-auto px-4 ${isMobile ? '-mt-10' : ''} flex justify-center`}>
              <PricingComponent isMobile={isMobile} />
            </div>
          </section>

          {/* Add this div to create a clean break between sections */}
          <div className="w-full h-12 bg-gray-900"></div>
          {/* Testimonial Section with Rating and Image Count */}

          {/* Seção de Depoimentos */}
          <section className="testimonials bg-gray-100 py-24 w-full">
            <div className="max-w-6xl mx-auto px-4">
              <h2 className="text-3xl font-bold text-gray-800 mb-12">
                Uma imagem vale mais que mil palavras
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="relative">
                  <p className="text-xl text-gray-700 italic mb-6 relative z-10">
                    Nunca pensei que cores amarelas ficariam bem em mim, mas me ver nas cores mudou minha opinião.
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <h3 className="text-lg font-semibold text-gray-800 mr-2">Laura</h3>
                      <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                        ✓ Usuário Verificado
                      </span>
                    </div>
                    <div className="flex">
                      {[...Array(5)].map((_, i) => (
                        <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <p className="text-xl text-gray-700 italic mb-6 relative z-10">
                    Eu costumava sempre usar os mesmos tons de cores com os quais me sentia confortável, mas ver os resultados da análise de cores em mim me ajudou a experimentar coisas novas.
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <h3 className="text-lg font-semibold text-gray-800 mr-2">Olympia</h3>
                      <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                        ✓ Usuário Verificado
                      </span>
                    </div>
                    <div className="flex">
                      {[...Array(5)].map((_, i) => (
                        <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Seção "Como funciona" redesenhada */}
          <section className="how-it-works bg-gray-900 py-16 sm:py-24">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-4xl font-bold text-center text-white mb-12 sm:mb-16">
                Envie algumas fotos e encontre sua paleta perfeita agora
              </h2>
              {isMobile ? (
                <div className="flex flex-col items-center">
                  <img
                    src="/grid_with_arrow_phone.png"
                    alt="Palette Hunt - Aplicativo móvel"
                    className="w-full max-w-4xl mb-4"
                  />
                  <img
                    src="/result.jpeg"
                    alt="Palette Hunt Análise de Cores"
                    className="w-full max-w-4xl mb-4 rounded-lg shadow-lg"
                  />
                  <img
                    src="/result2.jpeg"
                    alt="Palette Hunt Paleta de Cores"
                    className="w-full max-w-4xl rounded-lg shadow-lg"
                  />
                </div>
              ) : (
                <div className="flex items-start">
                  <div className="w-5/12 ml-6 pl-12 pr-4 pt-20">
                    <img
                      src="/grid_with_arrow.png"
                      alt="Palette Hunt cores"
                      className="w-full"
                    />
                  </div>
                  <div className="w-1/2 space-y-8 ml-2">
                    <img
                      src="/result.jpeg"
                      alt="Palette Hunt cores adequadas"
                      className="w-full rounded-lg shadow-lg"
                    />
                    <img
                      src="/result2.jpeg"
                      alt="Palette Hunt paleta de cores"
                      className="w-full rounded-lg shadow-lg"
                    />
                  </div>
                </div>
              )}
            </div>
          </section>

          {/* Seção de Demonstração do Playground */}
          <section className="playground-demo py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className={`flex flex-col ${isMobile || isTablet ? '' : 'lg:flex-row'} items-center justify-between ${isMobile || isTablet ? '' : 'lg:space-x-16'}`}>
                <div className={`${isMobile || isTablet ? 'w-full text-center' : 'lg:w-1/2 mb-8 lg:mb-0'}`}>
                  <h2 className="text-4xl font-bold mb-6 text-white">Seu Estilo, Reinventado</h2>
                  <p className="text-xl text-gray-300 mb-4">
                    Entre em nosso estúdio virtual de experimentação, onde a imaginação não tem limites. Veja-se em quantos looks e cores quiser.
                  </p>
                  <p className="text-xl text-gray-300 mb-6">
                    Nosso playground alimentado por IA é sua tela para exploração de estilo sem fim. Crie imagens de si mesmo em estilos que você nunca pensou que poderia usar.
                  </p>
                </div>
                <div className={`${isMobile || isTablet ? 'w-full' : 'lg:w-1/2'}`}>
                  <div className={`rounded-lg overflow-hidden shadow-xl ${isMobile || isTablet ? 'mt-6' : 'skew-right transition-all duration-300 hover:skew-none'}`}>
                    <ReactPlayer
                      url="/playground_movie.mp4"
                      width="100%"
                      height="auto"
                      controls
                      playing={false}
                      muted={false}
                      light={
                        <img 
                          src="/playground-thumbnail.png" 
                          alt="Palette Hunt Estúdio de Experimentação" 
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                          effect="blur"
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Seção de Demonstração da Análise de Cores */}
          <section className="color-analysis-demo py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className={`flex flex-col ${isMobile || isTablet  ? '' : 'lg:flex-row'} items-center justify-between ${isMobile ? '' : 'lg:space-x-16'}`}>
                <div className={`${isMobile || isTablet  ? 'w-full text-center' : 'lg:w-1/2 mb-8 lg:mb-0 order-2'}`}>
                  <h2 className="text-4xl font-bold mb-6 text-white">Desvende Seu Código de Cores</h2>
                  <p className="text-xl text-gray-300 mb-4">
                    Uma análise de cores não deveria ser apenas para os ricos. Descubra quais cores ficam bem em você e como usá-las a seu favor.
                  </p>
                  <p className="text-xl text-gray-300 mb-6">
                    Nós não apenas analisamos suas cores mais lisonjeiras, mas também mostramos como elas ficam em você.
                  </p>
                </div>
                <div className={`${isMobile || isTablet  ? 'w-full' : 'lg:w-1/2 order-1'}`}>
                  <div className={`rounded-lg overflow-hidden shadow-xl ${isMobile || isTablet  ? 'mt-6' : 'skew-left transition-all duration-300 hover:skew-none'}`} style={{ marginRight: isMobile ? '0' : '15%' }}>
                    <ReactPlayer
                      url="/playbook_demo.mov"
                      width="100%"
                      height="auto"
                      controls
                      playing={false}
                      muted={false}
                      light={
                        <img 
                          src="/playbook-thumbnail.jpeg" 
                          alt="Demonstração de Análise da Palette Hunt" 
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                          effect="blur"
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Seção de Depoimento do Playground */}
          <section className="comparison py-24 bg-gray-100 w-full">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-3xl font-bold text-gray-800 mb-12">
                Experimente novos estilos que você nunca pensou que poderia usar
              </h2>
              <div className="max-w-3xl mx-auto">
                <div className="relative">
                  <p className="text-2xl text-gray-700 italic mb-8 relative z-10">
                    O recurso de estúdio de experimentação é incrível. Eu literalmente consigo experimentar todas as cores e estilos que quero antes de ir à loja. É como ter um estilista pessoal na ponta dos dedos!
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <h3 className="text-xl font-semibold text-gray-800 mr-2">Louise</h3>
                      <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                        ✓ Usuário Verificado
                      </span>
                    </div>
                    <div className="flex">
                      {[...Array(5)].map((_, i) => (
                        <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Seção de Comparação Redesenhada */}
          <section className="price-comparison py-20">
            <div className="max-w-7xl mx-auto px-4">
              <h2 className="text-5xl font-bold text-center text-white mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">Experimente o Futuro da Análise de Cores</h2>
              <p className="text-xl text-center text-gray-300 mb-16 max-w-3xl mx-auto">
                Descubra cores que complementam perfeitamente suas características únicas, incluindo tons que você nunca experimentou antes. Desbloqueie todo o seu potencial de estilo.
              </p>
              <div className="flex flex-col lg:flex-row justify-between items-stretch space-y-12 lg:space-y-0 lg:space-x-12">
                {/* Método Tradicional */}
                <div className="w-full lg:w-1/2 bg-gradient-to-br from-gray-800 to-gray-900 rounded-3xl p-8 shadow-2xl transform transition-all duration-300 hover:scale-105 relative overflow-hidden">
                  <div className="absolute top-0 left-0 w-full h-full bg-opacity-10 bg-white rounded-3xl"></div>
                  <h3 className="text-3xl font-semibold mb-6 text-white relative z-10">Análise de Cores Presencial</h3>
                  <div className={`mb-8 h-72 rounded-xl overflow-hidden bg-gray-700 relative z-10 ${!(isMobile || isTablet) ? 'cursor-pointer' : ''}`}>
                    <img 
                      src="/traditional-analysis-1.webp" 
                      alt="Análise de Cores Tradicional da Palette Hunt" 
                      className={`w-full h-full object-cover object-top transition-transform duration-300 ${!(isMobile || isTablet) ? 'transform hover:scale-110' : ''}`}
                      style={!(isMobile || isTablet) ? { objectPosition: 'center -90px' } : {}}
                      effect="blur"
                    />
                  </div>
                  <ul className="space-y-4 relative z-10">
                    {[
                      "Consultas presenciais <strong>demoradas</strong>",
                      "Consultas <strong>caras</strong>",
                      "<strong>Sem experimentação</strong> das cores recomendadas",
                      "Limitado a <strong>especialistas locais</strong>"
                    ].map((item, index) => (
                      <li key={index} className="flex items-center text-white">
                        <svg className="w-5 h-5 mr-3 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M4.146 4.146a1.5 1.5 0 012.121 0L10 7.879l3.732-3.733a1.5 1.5 0 112.121 2.121L12.121 10l3.732 3.732a1.5 1.5 0 01-2.121 2.121L10 12.121l-3.732 3.732a1.5 1.5 0 01-2.121-2.121L7.879 10 4.146 6.268a1.5 1.5 0 010-2.122z" clipRule="evenodd" />
                        </svg>
                        <span dangerouslySetInnerHTML={{ __html: item }}></span>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Método Alimentado por IA */}
                <div className="w-full lg:w-1/2 rounded-3xl p-8 shadow-2xl transform transition-all duration-300 hover:scale-105 relative overflow-hidden">
                  <div className="absolute top-0 left-0 w-full h-full bg-opacity-10 bg-white rounded-3xl"></div>
                  <h3 className="text-3xl font-semibold mb-6 relative z-10 bg-clip-text text-transparent bg-gradient-to-r from-[#7C83FF] to-secondary">Análise de Cores Alimentada por IA</h3>
                  <div className="mb-8 h-72 rounded-xl overflow-hidden flex justify-center items-center space-x-4 relative z-10">
                    <div className={`w-1/2 h-full rounded-xl shadow-lg overflow-hidden ${!(isMobile || isTablet) ? 'cursor-pointer' : ''}`}>
                      <img 
                        src="/ai-analysis-1.png" 
                        alt="Análise de Cores Alimentada por IA da Palette Hunt 1" 
                        className={`w-full h-full object-cover transition-transform duration-300 ${!(isMobile || isTablet) ? 'transform hover:scale-110' : ''}`}
                        effect="blur"
                      />
                    </div>
                    <div className={`w-1/2 h-full rounded-xl shadow-lg overflow-hidden ${!(isMobile || isTablet) ? 'cursor-pointer' : ''}`}>
                      <img 
                        src="/ai-analysis-3.webp" 
                        alt="Análise de Cores Alimentada por IA 2" 
                        className={`w-full h-full object-cover transition-transform duration-300 ${!(isMobile || isTablet) ? 'transform hover:scale-110' : ''}`}
                        style={{ objectPosition: 'center 0px' }}
                        effect="blur"
                      />
                    </div>
                  </div>
                  <ul className="space-y-4 relative z-10">
                    {[
                      "Resultados rápidos em <strong>menos de uma hora</strong>",
                      "<strong>Acessível e disponível</strong> de qualquer lugar",
                      "<strong>Experimente</strong> suas recomendações de cores",
                      "<strong>Resultados personalizados</strong> com análise especializada"
                    ].map((item, index) => (
                      <li key={index} className="flex items-center text-white">
                        <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                        <span dangerouslySetInnerHTML={{ __html: item }}></span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="text-center mt-16">
                <p className="text-xl text-gray-300 mb-8">
                  Desbloqueie seu verdadeiro potencial de cores e transforme seu estilo com sua nova <strong>paleta de cores</strong>
                </p>
                <Link
                  to="/pt/login"
                  className="bg-primary text-white font-semibold py-4 px-10 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 text-xl inline-block shadow-lg"
                >
                  Experimente a Análise de Cores por IA Agora
                </Link>
              </div>
            </div>
          </section>

      {/* Seção de Preços */}
          <section className="pricing bg-gray-900 py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">Escolha Seu Plano</h2>
          <div className="flex flex-col md:flex-row justify-center items-stretch gap-8">
            {/* Plano Básico */}
            <div 
              className="bg-gray-800 rounded-lg p-8 w-full md:w-72 flex flex-col transition-all duration-300 ease-in-out transform hover:scale-105 cursor-pointer relative"
              onClick={scrollToGetStarted}
            >
              <div className="flex items-center mb-4">
                <h3 className="text-2xl font-semibold text-white">Plano Básico</h3>
                <span className="inline-block border border-green-500 text-green-500 text-xs font-bold px-2 py-1 rounded-full ml-2">
                  Único
                </span>
      </div>
              <div className="flex items-center mb-6">
                <p className="text-4xl font-bold">$15</p>
              </div>
              <ul className="mb-8 flex-grow">
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Teste de análise de cores
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  3 paletas de cores personalizadas
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  6 fotos suas nessas cores
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  50 créditos para o playground
                </li>
              </ul>
              <button className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 mt-auto">
                Escolher Básico
              </button>
            </div>

            {/* Plano Pro (Destacado) */}
            <div 
              className="bg-gradient-to-br from-primary to-secondary rounded-lg p-8 w-full md:w-72 flex flex-col transform md:scale-110 shadow-xl transition-all duration-300 ease-in-out hover:scale-115 cursor-pointer z-10 relative"
              onClick={scrollToGetStarted}
            >
              <div className="flex items-center mb-4">
                <h3 className="text-2xl font-semibold text-white">Plano Pro</h3>
                <span className="inline-block border border-white text-white text-xs font-bold px-2 py-1 rounded-full ml-2">
                  Único
                </span>
              </div>
              <div className="flex items-center mb-6">
                <p className="text-4xl font-bold">$21</p>
            </div>
              <ul className="mb-8 flex-grow">
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Teste de análise de cores
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  5 paletas de cores personalizadas
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  10 fotos suas nessas cores
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  150 créditos para o playground
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Teste Ouro ou Prata
                </li>
              </ul>
              <button className="bg-white text-primary font-semibold py-2 px-4 rounded-full hover:bg-gray-100 transition duration-300 mt-auto">
                Escolher Pro
              </button>
          </div>

            {/* Seção Fale Conosco */}
            <div 
              className="bg-gray-800 rounded-lg p-8 w-full sm:w-72 flex flex-col transition-all duration-300 ease-in-out transform hover:scale-105 cursor-pointer relative"
              onClick={() => window.location.href = 'mailto:hi@palettehunt.com'}
            >
              <h3 className="text-2xl font-semibold text-white mb-4">Empresarial</h3>
              <p className="text-lg mb-6">Adaptado às suas necessidades</p>
              <ul className="mb-8 flex-grow">
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Soluções de análise de cores personalizadas
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Opções de preços em volume
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Recursos personalizados
                </li>
              </ul>
              <button className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 mt-auto">
                Fale Conosco
              </button>
            </div>
          </div>
          </div>
        </section>

      {/* Seção de Perguntas Frequentes */}
      <section className="faq bg-gray-900 py-12 sm:py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-12">Perguntas Frequentes</h2>
          <div className="grid md:grid-cols-2 gap-6 justify-items-center">
            <div className="max-w-md">
              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">Como funciona o Palette Hunt?</summary>
                <p className="text-gray-300 text-left">
                  Usamos os algoritmos de visão de IA mais avançados para analisar suas fotos e gerar paletas de cores personalizadas que complementam suas características únicas e preferências de estilo.
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">Quanto tempo leva para receber os resultados?</summary>
                <p className="text-gray-300 text-left">
                  Você normalmente receberá seus resultados em até 1h. Se os resultados demorarem mais de 2h, por favor, nos envie um e-mail para <a href="mailto:hi@palettehunt.com" className=""><strong>hi@palettehunt.com</strong></a>
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">A qualidade dos resultados será boa?</summary>
                <p className="text-gray-300 text-left">
                  Sim, podemos garantir que a qualidade dos resultados será a melhor que você pode obter no mercado. Mas você precisa nos fornecer fotos de boa qualidade para maximizar a qualidade dos resultados.
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">Preciso enviar muitas fotos?</summary>
                <p className="text-gray-300 text-left">
                  Para resultados ideais, recomendamos enviar 8-10 fotos que mostrem diferentes ângulos, condições de iluminação e roupas. Isso determinará a qualidade dos resultados!
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">Posso manter as imagens geradas?</summary>
                <p className="text-gray-300 text-left">
                  Absolutamente! Você é proprietário de todas as imagens geradas pelo Palette Hunt. Tanto para o playground quanto para a análise de cores.
                </p>
              </details>

                  </div>
            <div className="max-w-md">
              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">Posso usar o Palette Hunt em dispositivos móveis?</summary>
                <p className="text-gray-300 text-left">
                  Sim, sem problemas! O Palette Hunt funciona tanto em dispositivos móveis quanto em desktop. Sinta-se à vontade para usar o dispositivo com o qual você se sente mais confortável!
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">Quão precisas são as recomendações de cores?</summary>
                <p className="text-gray-300 text-left">
                  Nosso processo de recomendação de cores foi testado minuciosamente para garantir que nossos resultados sejam os melhores que você pode obter. Nossa taxa de satisfação é extremamente alta entre nossos usuários.
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">Que tipo de fotos eu receberei?</summary>
                <p className="text-gray-300 text-left">
                  Para cada paleta de cores que você receber, você obterá 2 imagens suas nessa paleta de cores. Uma terá um fundo branco, como em um estúdio, e outra terá um fundo que combina com o tema de cores.
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">O que acontece se eu não gostar dos resultados?</summary>
                <p className="text-gray-300 text-left">
                  A qualidade é nossa prioridade máxima. No momento, não podemos oferecer reembolsos totais, pois cada análise de cores significa um custo significativo por usuário. Temos certeza, porém, de que encontraremos uma solução para você. Estamos sempre disponíveis em <a href="mailto:hi@palettehunt.com" className=""><strong>hi@palettehunt.com</strong></a>
                </p>
              </details>

              <details className="mb-6">
                <summary className="text-xl font-semibold mb-2 cursor-pointer">Minhas fotos serão compartilhadas com alguém?</summary>
                <p className="text-gray-300 text-left">
                  Não. Usamos as fotos apenas para treinar nossa IA, à qual ninguém mais tem acesso. Cada foto que você envia é excluída após uma semana.
                </p>
              </details>
              </div>
            </div>
          </div>
        </section>


        <Footer />
      </main>
    </div>
  </>
);
};

export default LandingPage;