import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CheckCircle2 } from 'lucide-react';
import { ClipLoader } from 'react-spinners';
import { Capacitor } from '@capacitor/core';
import { Stripe } from '@capacitor-community/stripe';

const translations = {
  en: {
    choosePlan: "Choose Your Color Analysis",
    selectPlan: "Start discovering your perfect color palette. Pay once, without subscriptions.",
    basicPlan: "Basic Plan",
    proPlan: "Pro Plan",
    enterprise: "Enterprise",
    custom: "Custom",
    oneOff: "One-off",
    choosePlanButton: "Choose Plan",
    contactUs: "Contact Us",
    colorAnalysisTest: "Color analysis test",
    tailoredPalettes: "tailored color palettes",
    picsInColors: "pics of you in those colors",
    playgroundCredits: "playground credits",
    goldSilverTest: "Gold or Silver test",
    tailoredSolutions: "Tailored color analysis solutions",
    bulkPricing: "Bulk pricing options",
    customisedFeatures: "Customised features",
    termsAgreement: "By proceeding, you agree to our",
    termsOfService: "Terms of Service",
    and: "and",
    privacyPolicy: "Privacy Policy",
  },
  es: {
    choosePlan: "Elige tu Plan",
    selectPlan: "Selecciona un plan que se adapte a tus necesidades y comienza a descubrir tu paleta de colores perfecta.",
    basicPlan: "Plan Básico",
    proPlan: "Plan Pro",
    enterprise: "Empresarial",
    custom: "Personalizado",
    oneOff: "Único pago",
    choosePlanButton: "Elegir Plan",
    contactUs: "Contáctanos",
    colorAnalysisTest: "Prueba de análisis de color",
    tailoredPalettes: "paletas de colores personalizadas",
    picsInColors: "fotos tuyas en esos colores",
    playgroundCredits: "créditos para el playground",
    goldSilverTest: "Prueba Oro o Plata",
    tailoredSolutions: "Soluciones de análisis de color personalizadas",
    bulkPricing: "Opciones de precios por volumen",
    customisedFeatures: "Características personalizadas",
    termsAgreement: "Al continuar, aceptas nuestros",
    termsOfService: "Términos de Servicio",
    and: "y",
    privacyPolicy: "Política de Privacidad",
  },
  pt: {
    choosePlan: "Escolha Seu Plano",
    selectPlan: "Selecione um plano que atenda às suas necessidades e comece a descobrir sua paleta de cores perfeita.",
    basicPlan: "Plano Básico",
    proPlan: "Plano Pro",
    enterprise: "Empresarial",
    custom: "Personalizado",
    oneOff: "Pagamento único",
    choosePlanButton: "Escolher Plano",
    contactUs: "Fale Conosco",
    colorAnalysisTest: "Teste de análise de cores",
    tailoredPalettes: "paletas de cores personalizadas",
    picsInColors: "fotos suas nessas cores",
    playgroundCredits: "créditos para o playground",
    goldSilverTest: "Teste Ouro ou Prata",
    tailoredSolutions: "Soluções de análise de cores personalizadas",
    bulkPricing: "Opções de preços em volume",
    customisedFeatures: "Recursos personalizados",
    termsAgreement: "Ao prosseguir, você concorda com nossos",
    termsOfService: "Termos de Serviço",
    and: "e",
    privacyPolicy: "Política de Privacidade",
  },
};

const PaymentScreen = ({ language = 'en' }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const t = translations[language];
  const isNativeMobile = Capacitor.isNativePlatform();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate(language === 'en' ? '/login' : `/${language}/login`);
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`, { token });
        if (!response.data.valid) {
          navigate(language === 'en' ? '/login' : `/${language}/login`);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        navigate(language === 'en' ? '/login' : `/${language}/login`);
      }
    };

    verifyToken();

    if (isNativeMobile) {
      Stripe.initialize({
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      });
    }
  }, [navigate, language]);

  const plans = [
    {
      id: 'basic',
      name: t.basicPlan,
      price: '$15',
      features: [
        t.colorAnalysisTest,
        `3 ${t.tailoredPalettes}`,
        `6 ${t.picsInColors}`,
        `50 ${t.playgroundCredits}`
      ]
    },
    {
      id: 'pro',
      name: t.proPlan,
      price: '$21',
      features: [
        t.colorAnalysisTest,
        `5 ${t.tailoredPalettes}`,
        `10 ${t.picsInColors}`,
        `150 ${t.playgroundCredits}`,
        t.goldSilverTest
      ]
    },
    {
      id: 'enterprise',
      name: t.enterprise,
      price: t.custom,
      features: [
        t.tailoredSolutions,
        t.bulkPricing,
        t.customisedFeatures
      ]
    }
  ];

  const renderMobilePricingCard = (plan) => (
    <div 
      key={plan.id}
      className={`bg-white shadow-lg mt-4 p-4 rounded-xl transition-all transform hover:scale-105 ${
        plan.id === 'pro' ? 'border-2 border-pink-500' : ''
      } ${selectedPlan === plan.id ? 'ring-4 ring-pink-500 ring-opacity-50' : ''}`}
      onClick={() => plan.id !== 'enterprise' && handlePlanSelection(plan.id)}
    >
      <h2 className="text-lg font-bold mb-2 text-gray-800">{plan.name}</h2>
      <div className="flex items-center mb-3">
        <p className="text-2xl font-bold text-gray-800">{plan.price}</p>
        {plan.id !== 'enterprise' && (
          <div className="border border-pink-500 text-pink-500 text-xs font-bold px-2 py-1 rounded-full ml-2">
            {t.oneOff}
          </div>
        )}
      </div>
      <ul className="space-y-2 mb-4 text-sm">
        <li className="flex items-center text-gray-600">
          <CheckCircle2 className="text-pink-500 mr-2" size={16} style={{ minWidth: '16px', minHeight: '16px' }} />
          <span><strong>Color analysis test</strong></span>
        </li>
        <li className="flex items-center text-gray-600">
          <CheckCircle2 className="text-pink-500 mr-2" size={16} style={{ minWidth: '16px', minHeight: '16px' }} />
          <span>{plan.id === 'basic' ? <><strong>3</strong> tailored color<strong> palettes</strong></> : <><strong>5</strong> tailored color<strong> palettes</strong></>}</span>
        </li>
        <li className="flex items-center text-gray-600">
          <CheckCircle2 className="text-pink-500 mr-2" size={16} style={{ minWidth: '16px', minHeight: '16px' }} />
          <span>{plan.id === 'basic' ? <><strong>6 pics of you</strong> in those colors</> : <><strong>10 pics of you</strong> in those colors</>}</span>
        </li>
        <li className="flex items-center text-gray-600">
          <CheckCircle2 className="text-pink-500 mr-2" size={16} style={{ minWidth: '16px', minHeight: '16px' }} />
          <span>{plan.id === 'basic' ? <><strong>50</strong> playground <strong>credits</strong></> : <><strong>150</strong> playground <strong>credits</strong></>}</span>
        </li>
        {plan.id === 'pro' && (
          <li className="flex items-center text-gray-600">
            <CheckCircle2 className="text-pink-500 mr-2" size={16} style={{ minWidth: '16px', minHeight: '16px' }} />
            <span><strong>Gold or Silver test</strong></span>
          </li>
        )}
      </ul>
      <button 
        className={`w-full py-2 px-4 rounded-full font-semibold text-sm transition-colors ${
          plan.id === 'pro'
            ? 'bg-pink-500 text-white hover:bg-pink-600'
            : 'bg-gray-200 text-black hover:bg-gray-300'
        } ${plan.id === 'basic' ? 'mt-12' : ''}`}
        onClick={() => plan.id !== 'enterprise' && handlePlanSelection(plan.id)}
        disabled={isProcessing || plan.id === 'enterprise'}
      >
        {isProcessing && selectedPlan === plan.id ? (
          <ClipLoader color="#ffffff" loading={true} size={16} />
        ) : (
          plan.id === 'enterprise' ? t.contactUs : t.choosePlanButton
        )}
      </button>
    </div>
  );

  const handlePlanSelection = async (planId) => {
    setSelectedPlan(planId);
    setIsProcessing(true);
    setError(null);

    const googleCredential = localStorage.getItem('googleCredential');
    if (!googleCredential) {
      navigate(language === 'en' ? '/login' : `/${language}/login`);
      return;
    }

    try {
      if (isNativeMobile) {
        const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/payment-checkout`, {
          credential: googleCredential,
          plan: planId,
          platform: 'mobile'
        });

        const { clientSecret, ephemeralKey, customerId, publishableKey } = response.data;

        console.log('Received data from server:', response.data);

        if (!clientSecret || !ephemeralKey || !customerId || !publishableKey) {
          throw new Error('Invalid response from server');
        }

        // Initialize Stripe with the received publishable key
        await Stripe.initialize({
          publishableKey: publishableKey
        });

        const initResult = await Stripe.createPaymentSheet({
          paymentIntentClientSecret: clientSecret,
          customerId: customerId,
          customerEphemeralKeySecret: ephemeralKey,
          merchantDisplayName: 'Palette Hunt',
          applePay: true,
          googlePay: false,
          style: 'alwaysLight', // or 'alwaysDark' based on your preference
        });

        console.log('Payment sheet initialized:', initResult);

        if (initResult.paymentSheetInitialized) {
          console.log('Presenting payment sheet...');
          const presentResult = await Stripe.presentPaymentSheet();
          console.log('Payment sheet presented:', presentResult);

          if (presentResult.paymentResult === 'completed') {
            console.log('Payment completed successfully');
            // Payment successful, navigate to the next page
            navigate('/upload');
          } else if (presentResult.paymentResult === 'canceled') {
            console.log('Payment was cancelled');
            setError('Payment was cancelled. Please try again.');
          } else {
            console.log('Payment failed:', presentResult.paymentResult);
            setError('Payment failed. Please try again.');
          }
        } else {
          console.log('Failed to initialize payment sheet');
          setError('Failed to initialize payment sheet. Please try again.');
        }
      } else {
        // Handle desktop payment (keep existing logic)
        const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/payment-checkout`, {
          credential: googleCredential,
          plan: planId
        });

        if (response.data.checkoutUrl) {
          window.location.href = response.data.checkoutUrl;
        } else {
          throw new Error('Unexpected response from server');
        }
      }
    } catch (error) {
      console.error('Payment error:', error);
      setError('An error occurred during payment. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <div className={`${isNativeMobile ? 'bg-pink-100' : 'bg-gradient-to-br from-gray-900 to-gray-800'} min-h-screen flex items-center justify-center`}>
        <ClipLoader color={isNativeMobile ? "#000000" : "#ffffff"} loading={isLoading} size={50} />
      </div>
    );
  }

  return (
    <div className={`${isNativeMobile ? 'bg-pink-100' : 'bg-gradient-to-br from-gray-900 to-gray-800'} ${isNativeMobile ? 'text-black' : 'text-white'} min-h-screen flex flex-col items-center justify-center p-4`}>
      {!isNativeMobile && (
        <Link to={language === 'en' ? '/' : `/${language}`} className="mb-4">
          <img src="/logo.png" alt="Palette Hunt Logo" className="h-12 w-auto" />
        </Link>
      )}
      
      <h1 className={`text-2xl sm:text-4xl font-bold mb-2 text-center ${isNativeMobile ? 'text-black' : 'bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text'}`}>
        {t.choosePlan}
      </h1>
      
      <p className={`${isNativeMobile ? 'text-base sm:text-2xl text-gray-700' : 'text-sm sm:text-xl text-gray-300'} mb-4 text-center max-w-xs sm:max-w-2xl`}>
        {t.selectPlan}
      </p>
      
      <div className={`grid ${isNativeMobile ? 'grid-cols-2 gap-4' : 'grid-cols-1 md:grid-cols-3 gap-8'} mb-6 w-full max-w-6xl`}>
        {plans.map((plan) => (
          isNativeMobile ? (
            plan.id !== 'enterprise' && renderMobilePricingCard(plan)
          ) : (
            // Keep the existing desktop version
            <div 
              key={plan.id}
              className={`${isNativeMobile 
                ? 'bg-white shadow-lg' 
                : 'bg-gray-800'} p-8 rounded-xl cursor-pointer transition-all transform hover:scale-105 ${
                plan.id === 'pro' ? `${isNativeMobile ? 'border-2 border-pink-500' : 'md:scale-110 bg-gradient-to-br from-primary to-secondary'}` : ''
              } ${selectedPlan === plan.id ? `${isNativeMobile ? 'ring-4 ring-pink-500 ring-opacity-50' : 'border-2 border-primary ring-4 ring-primary ring-opacity-50'}` : ''}`}
              onClick={() => plan.id !== 'enterprise' && handlePlanSelection(plan.id)}
            >
              <h2 className={`text-2xl font-bold mb-4 text-center ${isNativeMobile ? 'text-black' : 'text-white'}`}>{plan.name}</h2>
              <div className="flex items-center mb-6">
                <p className="text-4xl font-bold">{plan.price}</p>
                {plan.id !== 'enterprise' && (
                  <div className={`border ${isNativeMobile ? 'border-pink-500 text-pink-500' : plan.id === 'pro' ? 'border-white text-white' : 'border-green-500 text-green-500'} text-xs font-bold px-2 py-1 rounded-full ml-2`}>
                    {t.oneOff}
                  </div>
                )}
              </div>
              <ul className="space-y-3 mb-8">
                {plan.features.map((feature, index) => (
                  <li key={index} className={`flex items-center ${isNativeMobile ? 'text-gray-700' : 'text-gray-300'}`}>
                    <CheckCircle2 className={`${isNativeMobile ? 'text-pink-500' : plan.id === 'pro' ? 'text-white' : 'text-green-500'} mr-2`} size={20} />
                    <span className={isNativeMobile ? 'text-black' : plan.id === 'pro' ? 'text-white' : ''}>{feature}</span>
                  </li>
                ))}
              </ul>
              <button 
                className={`w-full py-2 px-4 rounded-full font-semibold transition-colors ${
                  isNativeMobile
                    ? plan.id === 'pro'
                      ? 'bg-pink-500 text-white hover:bg-pink-600'
                      : 'bg-gray-200 text-black hover:bg-gray-300'
                    : plan.id === 'pro' 
                      ? 'bg-white text-primary hover:bg-gray-100' 
                      : plan.id === 'enterprise'
                        ? 'bg-primary text-white hover:bg-opacity-90'
                        : 'bg-primary text-white hover:bg-opacity-90'
                }`}
                onClick={() => plan.id !== 'enterprise' && handlePlanSelection(plan.id)}
                disabled={isProcessing || plan.id === 'enterprise'}
              >
                {isProcessing && selectedPlan === plan.id ? (
                  <ClipLoader color="#ffffff" loading={true} size={20} />
                ) : (
                  plan.id === 'enterprise' ? t.contactUs : t.choosePlanButton
                )}
              </button>
            </div>
          )
        ))}
      </div>
      
      {!isNativeMobile && (
      <p className={`mt-4 ${isNativeMobile ? 'text-gray-700' : 'text-gray-400'} text-center max-w-xs sm:max-w-2xl text-xs sm:text-base`}>
        {t.termsAgreement} <Link to={language === 'en' ? '/terms-and-conditions' : `/${language}/terms-and-conditions`} className={`${isNativeMobile ? 'text-pink-500' : 'text-primary'} hover:underline`}>{t.termsOfService}</Link> {t.and} <Link to={language === 'en' ? '/privacy-policy' : `/${language}/privacy-policy`} className={`${isNativeMobile ? 'text-pink-500' : 'text-primary'} hover:underline`}>{t.privacyPolicy}</Link>.
      </p>
      )}
      
      {error && (
        <p className="text-red-500 mt-4 text-center text-sm">{error}</p>
      )}
    </div>
  );
};

export default PaymentScreen;