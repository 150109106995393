import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { CheckCircle2 } from 'lucide-react';
import { ClipLoader } from 'react-spinners';
import { Capacitor } from '@capacitor/core';
import { Stripe } from '@capacitor-community/stripe';

const Credits = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [isNativeMobile, setIsNativeMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`, { token });
        if (!response.data.valid) {
          navigate('/login');
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        navigate('/login');
      }
    };

    verifyToken();

    setIsNativeMobile(Capacitor.isNativePlatform());

    if (isNativeMobile) {
      Stripe.initialize({
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      });
    }
  }, [navigate]);

  const creditPlans = [
    { 
      id: 'basic_credits', 
      name: 'Basic Bundle', 
      price: '$5',
      credits: 70,
      images: 7,
      bonus: 0
    },
    { 
      id: 'pro_credits', 
      name: 'Pro Bundle', 
      price: '$15',
      credits: 300,
      images: 30,
      bonus: 20
    },
    { 
      id: 'premium_credits', 
      name: 'Premium Bundle', 
      price: '$40',
      credits: 1000,
      images: 100,
      bonus: 30
    }
  ];

  const handlePlanSelection = async (planId) => {
    setSelectedPlan(planId);
    setIsProcessing(true);
    setError(null);

    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      if (isNativeMobile) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/credits-checkout`,
          { token, plan: planId, platform: 'mobile' },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            withCredentials: true
          }
        );

        const { clientSecret, ephemeralKey, customerId, publishableKey } = response.data;

        console.log('Received data from server:', response.data);

        if (!clientSecret || !ephemeralKey || !customerId || !publishableKey) {
          throw new Error('Invalid response from server');
        }

        // Initialize Stripe with the received publishable key
        await Stripe.initialize({
          publishableKey: publishableKey
        });

        const initResult = await Stripe.createPaymentSheet({
          paymentIntentClientSecret: clientSecret,
          customerId: customerId,
          customerEphemeralKeySecret: ephemeralKey,
          merchantDisplayName: 'Palette Hunt',
          applePay: true,
          googlePay: false,
          style: 'alwaysLight', // or 'alwaysDark' based on your preference
        });

        console.log('Payment sheet initialized:', initResult);

        if (initResult.paymentSheetInitialized) {
          console.log('Presenting payment sheet...');
          const presentResult = await Stripe.presentPaymentSheet();
          console.log('Payment sheet presented:', presentResult);

          if (presentResult.paymentResult === 'completed') {
            console.log('Payment completed successfully');
            // Payment successful, navigate to the playground
            navigate('/playground');
          } else if (presentResult.paymentResult === 'canceled') {
            console.log('Payment was cancelled');
            setError('Payment was cancelled. Please try again.');
          } else {
            console.log('Payment failed:', presentResult.paymentResult);
            setError('Payment failed. Please try again.');
          }
        } else {
          console.log('Failed to initialize payment sheet');
          setError('Failed to initialize payment sheet. Please try again.');
        }
      } else {
        // Handle desktop payment (keep existing logic)
        const response = await axios.post(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/credits-checkout`,
          { token, plan: planId },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            withCredentials: true
          }
        );

        console.log('Checkout response:', response);

        if (response.data.checkoutUrl) {
          window.location.href = response.data.checkoutUrl;
        } else {
          throw new Error('Unexpected response from server');
        }
      }
    } catch (error) {
      console.error('Credits checkout error:', error);
      setError('Failed to initiate checkout. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-white min-h-screen flex items-center justify-center">
        <ClipLoader color="#ffffff" loading={isLoading} size={50} />
      </div>
    );
  }

  return (
    <div className={`${isNativeMobile ? 'bg-pink-100' : 'bg-gradient-to-br from-gray-900 to-gray-800'} ${isNativeMobile ? 'text-black' : 'text-white'} min-h-screen flex flex-col`}>
      <div className="container mx-auto px-4 pt-8 pb-16">
        <Link to="/" className="mb-8 inline-block">
          <img src="/logo.png" alt="Palette Hunt Logo" className="h-16 w-auto" />
        </Link>
        
        <h1 className="text-4xl sm:text-5xl font-bold mb-4 text-center bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">
          Recharge Your Creativity
        </h1>
        
        <p className="text-xl text-gray-300 mb-12 text-center max-w-2xl mx-auto">
          Choose a credit package to continue generating stunning AI-powered images and exploring your perfect color palette.
        </p>
        
        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12 w-full max-w-6xl mx-auto">
          {creditPlans.map((plan) => (
            <div 
              key={plan.id}
              className={`bg-gray-800 p-6 rounded-xl shadow-lg cursor-pointer transition-all transform hover:scale-105 ${
                plan.id === 'pro_credits' ? 'md:scale-110 bg-gradient-to-br from-primary to-secondary' : ''
              } ${selectedPlan === plan.id ? 'border-2 border-primary ring-4 ring-primary ring-opacity-50' : ''}`}
              onClick={() => handlePlanSelection(plan.id)}
            >
              <h2 className="text-2xl font-bold mb-4 text-white">{plan.name}</h2>
              <p className="text-4xl font-bold mb-4">{plan.price}</p>
              <ul className="space-y-2 mb-6">
                <li className="flex items-center text-white">
                  <CheckCircle2 className="text-green-500 mr-2" size={16} />
                  <span><strong>{plan.credits} Credits</strong></span>
                </li>
                <li className="flex items-center text-white">
                  <CheckCircle2 className="text-green-500 mr-2" size={16} />
                  <span>Generate up to <strong>{plan.images} images</strong></span>
                </li>
                {plan.bonus > 0 && (
                  <li className="flex items-center text-white">
                    <CheckCircle2 className="text-green-500 mr-2" size={16} />
                    <span><strong>{plan.bonus}% bonus</strong> credits</span>
                  </li>
                )}
              </ul>
              <button 
                className={`w-full py-2 px-4 rounded-full font-semibold transition-colors ${
                  plan.id === 'pro_credits' 
                    ? 'bg-white text-primary hover:bg-gray-100' 
                    : 'bg-primary text-white hover:bg-opacity-90'
                }`}
                onClick={() => handlePlanSelection(plan.id)}
                disabled={isProcessing}
              >
                {isProcessing && selectedPlan === plan.id ? (
                  <ClipLoader color="#ffffff" loading={true} size={20} />
                ) : (
                  'Select Plan'
                )}
              </button>
            </div>
          ))}
        </div>
        
        <p className="mt-8 text-gray-400 text-center max-w-2xl mx-auto">
          By proceeding, you agree to our <Link to="/terms-and-conditions" className="text-primary hover:underline">Terms of Service</Link> and <Link to="/privacy-policy" className="text-primary hover:underline">Privacy Policy</Link>.
        </p>
      </div>
    </div>
  );
};

export default Credits;
